export default class IndexRange {
  /**
   * Sets the range that the values get mapped to. Values outside
   * the mapped range will result in the first or last index
   *
   * @param min values <= min are mapped to the lowest index
   * @param max values >= max are mapped to the highest index
   */
  public setRange(min: number, max: number) {
    this.offset = min
    this.range = max - min
  }

  /**
   * Maps the value to an index between 0 and maxLength - 1.
   * Values that are outside the specified range are mapped
   * to the lowest or highest index
   *
   * @param value
   * @param maxLength
   */
  public getIndex(value: number, maxLength: number) {
    const maxIndex = maxLength - 1
    const relative = (value - this.offset) / this.range
    const index = Math.round(relative * maxIndex)
    return Math.min(Math.max(index, 0), maxIndex)
  }

  constructor() {
    this.offset = 0
    this.range = 1
  }

  public offset: number

  public range: number
}

import axios from "axios"
import SurfaceMap from "../api/scans/SurfaceMap"
import GridPoints, {
  AnnotationObject,
  SelectionMode,
} from "../api/scans/GridPoints"
import Feedback from "../api/scans/Feedback"
import { GateConfig } from "../api/scans/SharedTypes"
import base64url_encode from "./base64"
import { ColorMapConfig } from "./RangedColorMap"
import { GridPointsOptions } from "../providers/LocalStateProvider"
import { Profile } from "../providers/SharedContextProvider"

interface AssetInfo {
  pipeSize: string
  pipeThickness: string
}

export interface Note {
  id: string
  person: string
  text: string
  date: Date
}

export interface Media {
  src: string
  date: Date
}

export interface ProfileSettings {
  colorMapConfig: ColorMapConfig
  gridPointsConfig: GridPointsOptions
  gatesConfig: GateConfig
}

export interface Scan {
  id: string
  assetId: string
  assetOwner: string
  inspector: string
  location: string[]
  serviceProvider: string
  site: string
  thumb?: string
  images: Media[]
  audio: Media[]
  notes: Note[]
  annotations: AnnotationObject[]
  profiles?: Profile[]

  startTime: Date
  duration: string

  // details (maybe shouldn't be in list?)
  inspectionId: string
  assetMaterial: string
  containsUtData: boolean
  fileName: string
  fileSize: number
  numEntries: number

  [propName: string]:
    | string
    | string[]
    | Note[]
    | number
    | AssetInfo
    | Media[]
    | AnnotationObject[]
    | Profile[]
    | Date
    | boolean
    | undefined
}

// Get all scans
export async function getScans(): Promise<Scan[]> {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_SERVER}/v1/scans`
  )
  return data
}

// Get a single scan
export async function getScan(_: any, id: string): Promise<Scan> {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}`
  )
  return data
}

// Get profile details
export async function getProfile(
  _: any,
  id: string,
  profile: string | null
): Promise<ProfileSettings> {
  // TODO: Uncomment once Profile API is ready
  // const { data } = await axios.get(
  //   `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}/profile/${profile}`
  // )
  const data = {
    gridPointsConfig: {
      gridSize: profile === "001AJK1" ? 5 : 20,
      selectionMode: SelectionMode.Median,
      drawTimeline: profile === "001AJK1",
      removedIndices: [],
    },
    gatesConfig: {
      a: { start: 0, range: 1500, level: profile === "001AJK1" ? 2 : 80 },
      b: { start: 392, range: 500, level: 30 },
      c: null,
      isRelative: false,
    },
    colorMapConfig: {
      min: profile === "001AJK1" ? 195 : 205,
      max: profile === "001AJK1" ? 320 : 300,
    },
  }

  return data
}

// Get points result
export async function getPoints(
  _: any,
  id: string,
  config: object | null
): Promise<GridPoints> {
  const options = config
    ? base64url_encode(JSON.stringify(config, null, 0))
    : null
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}/points`,
    {
      params: {
        options,
      },
    }
  )
  // TODO: Add check if data exists, if not throw error
  return new GridPoints(data)
}

// TODO: can this return the object directly? What if data is an error?
export async function getMap(_: any, id: string): Promise<SurfaceMap> {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}/map`
  )
  return new SurfaceMap(data)
}

// TODO: can this return the object directly? What if data is an error?
export async function getFeedback(
  _: any,
  id: string,
  fbk: string
): Promise<Feedback> {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}/fbk/${fbk}`
  )
  return new Feedback(data)
}

// Edit all annotations
export async function editAnnotations({
  id,
  annotations,
}: {
  id: string
  annotations?: AnnotationObject[]
}): Promise<any> {
  const { data: originalData } = await axios.get(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}`
  )
  // Change to annotate endpoint once finished
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}`,
    {
      ...originalData,
      annotations,
    }
  )
  return data
}

// Add a note
export async function addNote({
  id,
  newNote,
}: {
  id: string
  newNote: string
}): Promise<any> {
  const note = {
    text: newNote,
    id: `foobar ${Math.random()}`,
    person: "foobar",
    date: new Date(),
  }
  const { data: originalData } = await axios.get(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}`
  )
  // Change to annotate endpoint once finished
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}`,
    {
      ...originalData,
      notes: [...originalData.notes, note],
    }
  )
  return data
}

// Add a Profile
export async function addProfile({
  id,
  profile,
  name,
}: {
  id: string
  profile: ProfileSettings
  name: string
}): Promise<any> {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_SERVER}/v1/scans/${id}/profiles`,
    {
      name,
      ...profile,
    }
  )
  return data
}

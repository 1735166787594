/* eslint-disable react/require-default-props */
import React from "react"
import Feedback from "../api/scans/Feedback"
import {Chart} from "primereact/chart";
import ArrayUtils from "../utils/ArrayUtils";
import {GateAlgorithm} from "../api/scans/SharedTypes";
import {computeGateResults} from "../utils/math/GateAlgorithms";
import ACF from "../utils/math/ACF";

type LineCoordinates = [{ x: number, y: number }, { x: number, y: number }] | undefined

const DISABLE_ANIMATION_OPTIONS = {
  animation: {
    duration: 0 // general animation time
  },
  hover: {
    animationDuration: 0 // duration of animations when hovering an item
  },
  responsiveAnimationDuration: 0 // animation duration after a resize
}
const MAX_ASCAN_VALUE = 140

export default function LineChart({fbk,}: { fbk: Feedback }): React.ReactElement {

  // A-Scan Chart
  let y = fbk.getAScan();
  let x = ArrayUtils.createIndexRange(0, y.length)
  let acf = fbk.getAcf();

  const gateResults = computeGateResults(fbk, {
    algorithm: GateAlgorithm.Autocorrelation,
    acf: {level: 30, start: 100, range: 300,}
  });

  const datasets = []

  // ACF
  // move to start at refPosA
  let maxAcfRange = 1.0;
  if (gateResults?.a) {
    const offset = gateResults.a.maxPos;
    const movedAcf = new Float32Array(acf.length);
    maxAcfRange = MAX_ASCAN_VALUE / gateResults.a.maxValue;
    for (let i = 0; i < (movedAcf.length - offset); i++) {
      movedAcf[offset + i] = acf[i];
    }
    acf = movedAcf;
  }
  // TODO: add ACF back in after webinar
/*  datasets.push({
    label: 'ACF',
    //@ts-ignore
    data: acf,
    fill: false,
    backgroundColor: '#bf42f5',
    borderColor: '#bf42f5',
    yAxisID: 'second-y-axis',
    xAxisID: 'first-x-axis',
    borderWidth: 1,
  });*/

  // A-Scan
  datasets.push({
    label: 'A-Scan',
    data: y,
    fill: false,
    backgroundColor: '#42A5F5',
    borderColor: '#42A5F5',
    yAxisID: 'first-y-axis',
    xAxisID: 'first-x-axis',
    borderWidth: 1,
  })

/*  // Additional data sets for gates
  const coordGateA = fbk.getGateA();
  if (coordGateA) {
    datasets.push({
      label: 'Gate A',
      //@ts-ignore
      data: coordGateA,
      fill: false,
      backgroundColor: '#ff6600',
      borderColor: '#ff6600',
      xAxisID: 'first-x-axis',
      yAxisID: 'first-y-axis',
      borderWidth: 5,
    })

    const ref = fbk.getRefA();
    if (ref) {
      // let level = coordGateA[0].y
      datasets.push({
        label: 'Ref-A',
        //@ts-ignore
        data: [{x: ref, y: 0}, {x: ref, y: MAX_ASCAN_VALUE}],
        fill: false,
        backgroundColor: '#ff6600',
        borderColor: '#ff6600',
        xAxisID: 'first-x-axis',
        yAxisID: 'first-y-axis',
        borderWidth: 2,
      })
    }
  }

  const coordGateB = fbk.getGateB();
  if (coordGateB) {
    datasets.push({
      label: 'Gate B',
      //@ts-ignore
      data: coordGateB,
      fill: false,
      backgroundColor: '#5cd65c',
      borderColor: '#5cd65c',
      xAxisID: 'first-x-axis',
      yAxisID: 'first-y-axis',
      borderWidth: 5,
    })

    const ref = fbk.getRefB();
    if (ref) {
      // let level = coordGateB[0].y
      datasets.push({
        label: 'Ref-A',
        //@ts-ignore
        data: [{x: ref, y: 0}, {x: ref, y: MAX_ASCAN_VALUE}],
        fill: false,
        backgroundColor: '#5cd65c',
        borderColor: '#5cd65c',
        xAxisID: 'first-x-axis',
        yAxisID: 'first-y-axis',
        borderWidth: 2,
      })
    }
  }*/

  const data = {
    labels: x,
    datasets: datasets
  };

  const options = {
    ...DISABLE_ANIMATION_OPTIONS,
    title: 'A-Scan',
    responsive: true,
    legend: false,
    elements: {
      point: {
        radius: 0 // disables points
      },
      line: {
        tension: 0 // disables bezier curves
      }
    },
    scales: {
      xAxes: [{
        id: 'first-x-axis',
        display: true,
        position: 'bottom',
        scaleLabel: {
          display: false,
          labelString: 'samples'
        },
        ticks: {
          min: 0,
          max: x.length,
          stepSize: 500,
          minRotation: 0,
          maxRotation: 0,
          autoSkipPadding: 10
        }
      }],
      yAxes: [{
        id: 'first-y-axis',
        display: true,
        position: 'left',
        ticks: {
          min: 0,
          max: MAX_ASCAN_VALUE,
          stepSize: 20,
          minRotation: 0,
          maxRotation: 0,
        }
      },{
        id: 'second-y-axis',
        display: true,
        position: 'right',
        ticks: {
          min: 0,
          max: maxAcfRange,
          stepSize: 0.2,
          minRotation: 0,
          maxRotation: 0,
        }
      }]
    }
  }

  return <Chart className="w-full" type="line" data={data} options={options}/>
}

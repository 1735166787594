import React, { useContext, ReactElement } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Link } from "react-router-dom"
import { ViewContext } from "../providers/ViewStateProvider"
import ActionBar from "./ActionBar"
import { Scan } from "../utils/scanService"

interface ColumnProps {
  field: string
  header: string
  sortable?: boolean
  filter?: boolean
  filterPlaceholder?: string
}

interface TableProps {
  scans: Scan[]
}

const cols = [
  {
    field: "assetId",
    header: "Asset ID",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "site",
    header: "Site",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "location",
    header: "Location",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "assetOwner",
    header: "Company",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "inspector",
    header: "Inspector",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "containsUtData",
    header: "UT Data",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "startTime",
    header: "Start Time",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "duration",
    header: "Duration",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "numEntries",
    header: "Measurements",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "fileSize",
    header: "File Size (bytes)",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
  {
    field: "fileName",
    header: "File Name",
    filter: true,
    filterPlaceholder: "Search",
    sortable: true,
  },
]

const cellTemplate = (
  rowData: Scan,
  column: ColumnProps
): ReactElement => {
  // TODO: fix boolean display
  let value = rowData[column.field]
  if (value === true){
    value = 'true'
  }else if (value === false){
    value = 'false'
  }
  return (
    <div>
      <Link to={`/scan/${rowData.id}`} className="datatable-link">
        {value}
      </Link>
    </div>
  )
}

const Table: React.FC<TableProps> = ({ scans }) => {
  const view = useContext(ViewContext)

  const setView = (property: object): void => {
    view.dispatch({ type: "update", payload: property })
  }

  const dynamicColumns = cols.map((col: ColumnProps, i: number) => {
    return (
      <Column
        body={cellTemplate}
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        filter={col.filter}
        filterPlaceholder={col.filterPlaceholder}
      />
    )
  })

  return (
    <div>
      <ActionBar
        totalScans={scans.length}
        selectedScans={view.state.selectedScans}
      />
      <DataTable
        paginator={scans.length > 50}
        paginatorPosition="both"
        onPage={(e): void => setView({ first: e.first })}
        first={view.state.first}
        rows={50}
        value={scans}
        sortField="name"
        sortOrder={1}
        selection={view.state.selectedScans}
        onSelectionChange={(e): void => setView({ selectedScans: e.value })}
      >
        <Column style={{ width: "3rem" }} selectionMode="multiple" />
        {dynamicColumns}
      </DataTable>
    </div>
  )
}

export default Table

import React, { Reducer } from "react"
import { ColorMapConfig } from "../utils/RangedColorMap"
import { GridPointsConfig, SelectionMode } from "../api/scans/GridPoints"
import { GateConfig } from "../api/scans/SharedTypes"
import { ProfileSettings } from "../utils/scanService"

export const defaultGridValues = [1, 5, 10, 20]

export type Cell = null | { col: number; row: number }

export type GridPointsOptions = {
  gridSize: number
  selectionMode: SelectionMode
  drawTimeline: boolean
  removedIndices: number[] | null[]
}

type LocalState = {
  id: string
  canvas: HTMLCanvasElement
  colorMapConfig: ColorMapConfig
  gridPointsConfig: GridPointsOptions
  selectedCell?: Cell
  hoveredCell?: Cell
  customFields: any[]
  gatesConfig: GateConfig
  initialGatesConfig: GateConfig
  selectedProfile?: string
  profileChanged: boolean
}

type State = {
  state: LocalState
  setCanvas: (canvas: HTMLCanvasElement) => void
  setPreviousState: (data: string) => void
  setSelectedCell: (data: Cell) => void
  setHoveredCell: (data: Cell) => void
  setColorMapConfig: (data: ColorMapConfig) => void
  setGridPointsConfig: (data: GridPointsConfig) => void
  resetGridPointsConfig: (property: keyof GridPointsOptions) => void
  setGatesConfig: (data: GateConfig) => void
  setProfile: (data: string) => void
  setProfileData: (data: ProfileSettings) => void
  setInitialGatesConfig: (data: GateConfig) => void
  resetLocalState: () => void
  removeIndex: (data: number) => void
}

type StateAction =
  | { type: Action.SetCanvas; canvas: HTMLCanvasElement }
  | { type: Action.SetSelectedCell; data: Cell }
  | { type: Action.SetHoveredCell; data: Cell }
  | { type: Action.SetColorMapConfig; data: ColorMapConfig }
  | { type: Action.SetGridPointsConfig; data: GridPointsConfig }
  | { type: Action.ResetGridPointsConfig; property: keyof GridPointsOptions }
  | { type: Action.SetGatesConfig; data: GateConfig }
  | { type: Action.SetInitialGatesConfig; data: GateConfig }
  | { type: Action.ResetLocalState }
  | { type: Action.SetPreviousState; data: string }
  | { type: Action.RemoveIndex; data: number }
  | { type: Action.SetProfile; data: string }
  | { type: Action.SetProfileData; data: ProfileSettings }

enum Action {
  SetCanvas,
  SetSelectedCell,
  SetHoveredCell,
  SetColorMapConfig,
  SetGridPointsConfig,
  ResetGridPointsConfig,
  SetGatesConfig,
  SetInitialGatesConfig,
  ResetLocalState,
  SetPreviousState,
  RemoveIndex,
  SetProfile,
  SetProfileData,
}

const defaultState = {
  id: null,
  canvas: {},
  colorMapConfig: {},
  gridPointsConfig: {
    gridSize: 5,
    selectionMode: SelectionMode.Median,
    drawTimeline: false,
    removedIndices: [],
  },
  selectedCell: null,
  customFields: [],
  gatesConfig: null,
  selectedProfile: null,
  profileChanged: false,
}

const LocalContext = React.createContext<State | undefined>(undefined)

let savedState = JSON.parse(localStorage.getItem("localState") as string)

function stateReducer(prevState: LocalState, action: StateAction) {
  const customFields = []
  let newConfig = {}
  switch (action.type) {
    case Action.SetCanvas:
      return {
        ...prevState,
        canvas: action.canvas,
      }
    case Action.SetSelectedCell:
      return {
        ...prevState,
        selectedCell: action.data,
      }
    case Action.SetHoveredCell:
      return {
        ...prevState,
        hoveredCell: action.data,
      }
    case Action.SetColorMapConfig:
      return {
        ...prevState,
        colorMapConfig: action.data,
      }
    case Action.SetGridPointsConfig:
      newConfig = action.data
      if (
        action.data.gridSize &&
        !defaultGridValues.includes(action.data.gridSize)
      )
        customFields.push("gridSize")
      return {
        ...prevState,
        gridPointsConfig: newConfig,
        customFields,
        profileChanged: true,
      }
    case Action.ResetGridPointsConfig:
      return {
        ...prevState,
        gridPointsConfig: {
          ...prevState.gridPointsConfig,
          [action.property]: defaultState.gridPointsConfig[action.property],
        },
        customFields: {
          ...prevState.customFields.filter((item) => item !== action.property),
        },
        profileChanged: true,
      }
    case Action.SetGatesConfig:
      return {
        ...prevState,
        gatesConfig: action.data,
        profileChanged: true,
      }
    case Action.ResetLocalState:
      return defaultState
    case Action.SetInitialGatesConfig:
      return {
        ...prevState,
        initialGatesConfig: action.data,
      }
    case Action.SetProfile:
      return {
        ...prevState,
        selectedProfile: action.data,
        profileChanged: false,
      }
    case Action.SetProfileData:
      return {
        ...prevState,
        ...action.data,
        profileChanged: false,
      }
    case Action.RemoveIndex:
      return {
        ...prevState,
        gridPointsConfig: {
          ...prevState.gridPointsConfig,
          removedIndices: [
            ...new Set([
              ...prevState.gridPointsConfig.removedIndices,
              action.data,
            ]),
          ],
        },
      }
    case Action.SetPreviousState:
      return {
        ...prevState,
        ...savedState[action.data],
        id: action.data,
      }
    default:
      throw new Error("Not implemented.")
  }
}

function LocalStateProvider({ children }: { children: React.ReactElement }) {
  const [state, dispatch] = React.useReducer<Reducer<any, any>>(
    stateReducer,
    defaultState
  )

  const localContext = React.useMemo(
    () => ({
      state,
      setPreviousState: (data: string) => {
        dispatch({ type: Action.SetPreviousState, data })
      },
      setCanvas: (canvas: HTMLCanvasElement) => {
        dispatch({ type: Action.SetCanvas, canvas })
      },
      setSelectedCell: (data: Cell) => {
        dispatch({ type: Action.SetSelectedCell, data })
      },
      setHoveredCell: (data: Cell) => {
        dispatch({ type: Action.SetHoveredCell, data })
      },
      setColorMapConfig: (data: ColorMapConfig) => {
        dispatch({ type: Action.SetColorMapConfig, data })
      },
      setGridPointsConfig: (data: GridPointsConfig) => {
        dispatch({ type: Action.SetGridPointsConfig, data })
      },
      resetGridPointsConfig: (property: keyof GridPointsOptions) => {
        dispatch({ type: Action.ResetGridPointsConfig, property })
      },
      setGatesConfig: (data: GateConfig) => {
        dispatch({ type: Action.SetGatesConfig, data })
      },
      setInitialGatesConfig: (data: GateConfig) => {
        dispatch({ type: Action.SetInitialGatesConfig, data })
      },
      setProfile: (data: string) => {
        dispatch({ type: Action.SetProfile, data })
      },
      setProfileData: (data: ProfileSettings) => {
        dispatch({ type: Action.SetProfileData, data })
      },
      removeIndex: (data: number) => {
        dispatch({ type: Action.RemoveIndex, data })
      },
      resetLocalState: () => {
        dispatch({ type: Action.ResetLocalState })
      },
    }),
    [state]
  )

  React.useEffect(() => {
    savedState = {
      ...savedState,
      [state.id]: {
        colorMapConfig: state.colorMapConfig,
        gridPointsConfig: state.gridPointsConfig,
        selectedCell: state.selectedCell,
        gatesConfig: state.gatesConfig,
        selectedProfile: state.selectedProfile,
        profileChanged: state.profileChanged,
      },
    }

    localStorage.setItem("localState", JSON.stringify(savedState))
  }, [state])

  return (
    <LocalContext.Provider value={localContext}>
      {children}
    </LocalContext.Provider>
  )
}

function useLocalState() {
  const context = React.useContext(LocalContext)
  if (context === undefined) {
    throw new Error("useLocalState must be used within a CountProvider")
  }
  return context
}

export { LocalStateProvider, useLocalState, LocalContext }

// @ts-ignore
import colormap from "colormap"
import IndexRange from "./IndexRange"

export interface ColorMapConfig {
  min?: number
  max?: number
}

/**
 * Colormap that maps a settable range, rather than only [0-1]
 */
export default class RangedColorMap extends IndexRange {
  public setConfig(config: ColorMapConfig): void {
    const { min, max } = config
    if (min !== undefined && max !== undefined) {
      this.setRange(min, max)
    }
  }

  public getMinBound(): number {
    return this.minBound
  }

  public getMaxBound(): number {
    return this.maxBound
  }

  public getColor(value: number): string {
    const index = this.getIndex(value, this.colors.length)
    return this.colors[index]
  }

  constructor(minBound: number, maxBound: number) {
    super()
    const colorSteps = 100
    this.colors = colormap({
      colormap: "jet",
      nshades: colorSteps,
      format: "hex",
      alpha: 1,
    }).reverse()
    // ET Typical color palette for corrosion mapping
    /*this.colors = [
      "#C0C0C0", // Nominal, NDD
      "#828884", // 10-19% Wall Loss
      "#9595EC", // 20-29% Wall Loss
      "#FBF92F", // 30-39% Wall Loss
      "#06F400", // 40-49% Wall Loss
      "#06FCFE", // 50-59% Wall Loss
      "#FC9C11", // 60-69% Wall Loss
      "#FC97CC", // 70-79% Wall Loss
      "#ED0DF3", // 80-89% Wall Loss
      "#F70200", // 90% - Thru Wall
    ].reverse();*/
    this.minBound = minBound
    this.maxBound = maxBound
    this.setRange(minBound, maxBound)
  }

  public setRange(min: number, max: number) {
    min = this.checkInRangeOrElse(min, this.minBound)
    max = this.checkInRangeOrElse(max, this.maxBound)
    super.setRange(min, max)
  }

  private checkInRangeOrElse(value:number, fallback: number){
    return (value !== undefined && value <= this.maxBound && value >= this.minBound) ? value : fallback
  }

  private readonly colors: string[]

  private readonly minBound: number

  private readonly maxBound: number
}

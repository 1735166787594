import React from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import { useOktaAuth } from "@okta/okta-react"
import Navbar from "../components/Navbar"
import { getScan } from "../utils/scanService"
import ViewCustomization from "../components/ViewCustomization"
import ViewImageArea from "../components/ViewImageArea"
import Heading from "../components/Heading"
import ScanInformation from "../components/ScanInformation"
import ScrollToTop from "../components/ScrollToTop"
import ErrorScreen from "./ErrorScreen"
import FormattedDate from "../components/FormattedDate"
import FormattedDuration from "../components/FormattedDuration"
import { useSharedContext } from "../providers/SharedContextProvider"
import LoadingScreen from "./LoadingScreen"
import { useLocalState } from "../providers/LocalStateProvider"
import Button from "../components/Button"
import useAuthInterceptor from "../utils/useAuthInterceptor"

const Detail: React.FC = () => {
  const { id } = useParams()

  const { authService } = useOktaAuth()
  const { state: shared, setAvailableProfiles } = useSharedContext()
  const { setPreviousState } = useLocalState()
  useAuthInterceptor()

  const login = () => authService.login(`/scan/${id}`)

  // disables types until proper types land https://github.com/tannerlinsley/react-query/discussions/362
  // @ts-ignore
  const { isLoading, isError, data, error } = useQuery(
    ["scanDetail", id],
    getScan,
    {
      retry: 1,
    }
  )

  React.useEffect(() => {
    setPreviousState(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  React.useEffect(() => {
    if (data?.profiles) {
      setAvailableProfiles(data.profiles)
    }
  }, [data])

  if (isError && error) {
    // TS Ignore because of non-existing TS-support of react-query for now
    return (
      <ErrorScreen>
        <span className="mr-3">Unable to access the scan.</span>
        {/* @ts-ignore */}
        {error?.response?.status === 401 && (
          <Button variant="primary" onClick={login}>
            Login
          </Button>
        )}
      </ErrorScreen>
    )
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!data) return <ErrorScreen>Page not found</ErrorScreen>
  const {
    assetId,
    thumb,
    duration,
    startTime,
    assetOwner,
    site,
    inspector,
  } = data

  return (
    <div>
      <ScrollToTop />
      <Navbar backLink />
      <div className="bg-gray-200 py-8">
        <div className="shadow container bg-white rounded">
          <div className="border-b">
            <div className="flex space-x-4 items-center justify-between p-8">
              <div className="space-y-2 flex-grow">
                <Heading isLoading={isLoading} size="sm" muted>
                  {site} / {assetOwner}
                </Heading>
                <Heading isLoading={isLoading}>{assetId}</Heading>
                <div>
                  <div className="text-muted">
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <FormattedDate date={startTime} />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="border p-3 rounded space-y-2 text-right">
                  <div>
                    <Heading size="sm" muted>
                      Inspector
                    </Heading>
                    <div>{inspector}</div>
                  </div>
                  <div>
                    <Heading size="sm" muted>
                      Duration
                    </Heading>
                    <FormattedDuration duration={duration} />
                  </div>
                </div>
                {isLoading ? (
                  <Skeleton width={100} height={100} />
                ) : (
                  thumb && (
                    <img
                      src={thumb}
                      width="100"
                      height="100"
                      alt={assetId}
                      className="rounded border"
                    />
                  )
                )}
              </div>
            </div>
          </div>
          <div>
            {!isLoading && <ViewImageArea scanId={id} />}
            {shared.gridPoints && <ViewCustomization scanId={id} />}
          </div>
        </div>
        <div className="container py-8">
          {!isLoading && <ScanInformation data={data} isLoading={isLoading} />}
        </div>
      </div>
    </div>
  )
}

export default Detail

import React, { Context, createContext, useReducer, useEffect } from "react"
import { Scan } from "../utils/scanService"

export interface ViewState {
  mapType: "COLOR_MAP" | "OTHER_MAP"
  selectedScans: Scan[]
  first?: number
  customFields: string[]
}

interface ViewContext {
  state: ViewState
  dispatch: React.Dispatch<any>
}

type Action =
  | { type: "update"; payload: ViewState }
  | { type: "reset"; payload: keyof ViewState }

const defaultState = {
  selectedScans: [],
  first: 0,
}

const reducer = (state: ViewState, action: Action): ViewState => {
  let newState = {}
  switch (action.type) {
    case "update":
      newState = action.payload
      break
    default:
      throw new Error()
  }
  return { ...state, ...newState }
}

const ViewContext: Context<ViewContext> = createContext({} as ViewContext)

const savedState = JSON.parse(localStorage.getItem("viewState") as string)

const initialState = {
  ...defaultState,
  ...savedState,
}

const ViewStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    localStorage.setItem("viewState", JSON.stringify(state))
  }, [state])

  return (
    <ViewContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ViewContext.Provider>
  )
}

export { ViewStateProvider, ViewContext, defaultState }

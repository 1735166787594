import {BufferGeometry, Face3, Geometry, Vector2, Vector3} from "three"

/**
 * Should not be used directly
 */
export interface JsonMap {
  xMin: number
  xMax: number
  yMin: number
  yMax: number
  data: {
    rows: number
    cols: number
    channels: number
    values: number[]
  }
}

/**
 * Wraps JSON map data and extracts
 * usable 3D geometry information
 */
export default class SurfaceMap {

  // UV coordinates (never change)
  private readonly geometry: BufferGeometry
  private readonly uvEdges: Vector2[]
  private readonly translation: Vector3
  private readonly radius: number

  // Pixel path (depends on resolution)
  private pxEdges: Path2D
  private pxWidth?: number
  private pxHeight?: number

  constructor(json: JsonMap) {
    const mapping = new JsonWrapper(json)
    this.geometry = mapping.createGeometry()
    this.uvEdges = mapping.getEdgePointsUv()
    this.pxEdges = new Path2D()

    // Find geometry center
    const center = new Vector3();
    this.geometry.computeBoundingBox();
    this.geometry.boundingBox?.getCenter(center);
    this.geometry.translate(-center.x, -center.y, -center.z)
    this.translation = center;

    // Find geometry radius
    this.geometry.computeBoundingSphere()
    if (this.geometry?.boundingSphere) {
      this.radius = this.geometry.boundingSphere.radius
    } else {
      throw new Error('Could not compute bounding sphere')
    }

  }

  public getEdgePath(width: number, height: number): Path2D {
    // Resolution rarely changes -> use existing
    if (this.pxWidth === width && this.pxHeight === height) {
      return this.pxEdges;
    }

    // Rebuild path
    const path = new Path2D()
    path.moveTo(this.uvEdges[0].x * width, this.uvEdges[0].y * height)
    for (let i = 1; i < this.uvEdges.length; i++) {
      path.lineTo(this.uvEdges[i].x * width, this.uvEdges[i].y * height)
    }

    // Cache result
    this.pxEdges = path
    this.pxWidth = width
    this.pxHeight = height
    return this.pxEdges
  }

  /**
   * The translation to move the centered geometry
   * to the original origin
   */
  public getTranslation(): Vector3 {
    return this.translation;
  }

  /**
   * Returns the radius of a bounding sphere
   * that would cover the entire geometry
   */
  public getRadius(): number {
    return this.radius
  }

  /**
   * Geometry representing the 3D shape. The geometry is centered
   * and
   */
  public getGeometry(): BufferGeometry {
    return this.geometry;
  }

}

class JsonWrapper {

  constructor(json: JsonMap) {
    if (json.data.channels < 5) {
      throw new Error('Expected map to have at least 5 channels')
    }

    this.numRows = json.data.rows
    this.numCols = json.data.cols

    // Convert raw matrix to readable elements
    const numElements = this.numRows * this.numCols
    const numChannels = json.data.channels
    const values = json.data.values

    // Normalize 2D location to get relative UV space
    const xOffset = json.xMin;
    const yOffset = json.yMin;
    const xRange = json.xMax - json.xMin;
    const yRange = json.yMax - json.yMin;

    this.vertices = new Array(numElements)
    this.uvs = new Array(numElements)

    for (let i = 0; i < numElements; i++) {

      // Components are [uv vertex] for each row/col
      let offset = numChannels * i
      const uvX = (values[offset++] - xOffset) / xRange
      const uvY = (values[offset++] - yOffset) / yRange
      const x = values[offset++]
      const y = values[offset++]
      const z = values[offset++]

      this.uvs[i] = new Vector2(uvX, uvY)
      this.vertices[i] = new Vector3(x, y, z)

    }

  }

  private readonly numRows: number
  private readonly numCols: number

  private readonly uvs: Vector2[]
  private readonly vertices: Vector3[]

  private getIndex(row: number, col: number): number {
    return col * this.numRows + row // column major
  }

  // Returns the points that describe the edge of the mapped UV area
  public getEdgePointsUv(): Vector2[] {
    const uv = (row: number, col: number) => this.uvs[this.getIndex(row, col)]

    let points = []
    let row = 0;
    let col = 0;

    // top left to top right
    for (; col < this.numCols - 1; col++) {
      points.push(uv(row, col));
    }

    // top right to bottom right
    for (; row < this.numRows - 1; row++) {
      points.push(uv(row, col));
    }

    // bottom right to bottom left
    for (; col > 0; col--) {
      points.push(uv(row, col));
    }

    // bottom left to top left (all the way to row zero)
    for (; row >= 0; row--) {
      points.push(uv(row, col));
    }

    return points
  }

  public createGeometry(): BufferGeometry {
    const geometry = new Geometry();
    geometry.vertices = this.vertices;
    const uv = this.uvs;

    // Manually build 2x triangular faces for every square
    // see https://threejs.org/docs/#api/en/core/Face3
    for (let col = 0; col < this.numCols - 1; col++) {
      for (let row = 0; row < this.numRows - 1; row++) {

        // indices into the four corners of the current square tile
        const topLeft = this.getIndex(row, col);
        const topRight = this.getIndex(row, col + 1);
        const bottomLeft = this.getIndex(row + 1, col);
        const bottomRight = this.getIndex(row + 1, col + 1);

        // create 2 triangles (uses index into vertices)
        geometry.faces.push(new Face3(topLeft, topRight, bottomLeft));
        geometry.faces.push(new Face3(bottomLeft, topRight, bottomRight));

        // create matching UVs for texture mapping (UV is [0-1] and maps to image coordinates)
        geometry.faceVertexUvs[0].push([uv[topLeft], uv[topRight], uv[bottomLeft]])
        geometry.faceVertexUvs[0].push([uv[bottomLeft], uv[topRight], uv[bottomRight]])

      }
    }

    // Compute the faces and vertex normals automatically
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();

    // coordinates never change
    geometry.uvsNeedUpdate = false;
    geometry.verticesNeedUpdate = false;
    return new BufferGeometry().fromGeometry(geometry);
  }

}
import React from "react"
import Skeleton from "react-loading-skeleton"
import { useMutation, queryCache } from "react-query"
import { useParams } from "react-router-dom"
import { Note, addNote } from "../utils/scanService"
import Heading from "./Heading"
import Button from "./Button"
import Input from "./Input"
import Modal from "./Modal"

import FormattedDate from "./FormattedDate"

interface NotesProps {
  isLoading?: boolean
  notes: Note[]
}

const Notes: React.FC<NotesProps> = ({ isLoading, notes }) => {
  const { id } = useParams()

  const [showNoteDialog, setShowNoteDialog] = React.useState(false)
  const [newNote, setNewNote] = React.useState("")

  const [mutate] = useMutation(addNote, {
    onSuccess: () => {
      // @ts-ignore
      queryCache.invalidateQueries(["scanDetail", id])
    },
  })

  const submit = async (e: any) => {
    e.preventDefault()
    await mutate({ id, newNote })
    setShowNoteDialog(false)
  }

  return (
    <>
      <div className="flex justify-between">
        <Heading>Notes</Heading>
        <Button
          variant="secondary"
          title="Not implemented"
          disabled
          onClick={(): void => setShowNoteDialog(true)}
        >
          Add Note
        </Button>
      </div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div>
          {notes?.length > 0 ? (
            <div className="space-y-2">
              {notes.map((note: Note) => (
                <div key={note.id} className="flex flex-col">
                  <div>{note.text}</div>
                  <div className="text-xs text-muted">
                    {note.person} <FormattedDate date={note.date} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>No notes found</div>
          )}
        </div>
      )}
      <Modal
        ariaLabel="Customize your View"
        isOpen={showNoteDialog}
        close={(): void => setShowNoteDialog(true)}
      >
        <div className="space-y-4">
          <Heading>Add a note</Heading>
          <form onSubmit={submit} className="space-y-2">
            <Input
              type="text"
              label="Text"
              name="text"
              value={newNote}
              onChange={(e): void => {
                setNewNote(e.target.value)
              }}
            />
            <div className="space-x-2">
              <Button
                size="lg"
                variant="secondary"
                onClick={(): void => setShowNoteDialog(false)}
              >
                Cancel
              </Button>
              <Button size="lg" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default Notes

import React, { SetStateAction } from "react"
import { useQuery } from "react-query"
import { useLocalState } from "../providers/LocalStateProvider"
import { useSharedContext } from "../providers/SharedContextProvider"
import { getFeedback } from "../utils/scanService"
import LineChart from "./LineChart"
import Feedback from "../api/scans/Feedback"
import Button from "./Button"

function useFeedback(scanId: string, fbkId: string) {
  // @ts-ignore (react-query types)
  return useQuery(["getFeedback", scanId, fbkId], getFeedback, {
    enabled: fbkId,
  })
}

function AScan({ scanId, fbkId }: { scanId: string; fbkId: string }) {
  const { data } = useFeedback(scanId, fbkId)
  const [chartData, setChartData] = React.useState<Feedback>()
  React.useEffect(() => {
    if (data) {
      setChartData(data)
    }
  }, [data])
  if (chartData) {
    return <LineChart fbk={chartData} />
  }
  return null
}

const ENABLE_REMOVE_INDEX = false // disabled while it's not yet implemented
export default function SelectedScanArea({ scanId }: { scanId: string }) {
  const { state } = useLocalState()
  const { state: shared } = useSharedContext()
  const { removeIndex } = useLocalState()
  const [selectedCellValue, setSelectedCellValue] = React.useState<
    SetStateAction<any>
  >(null)

  React.useEffect(() => {
    if (state.selectedCell) {
      setSelectedCellValue(
        shared.gridPoints.getCellValue(
          state.selectedCell.row,
          state.selectedCell.col
        )
      )
    }
  }, [shared.gridPoints, state.selectedCell])

  React.useEffect(() => {
    // TODO: is this for selecting areas?
    // if (selectedCellValue) {
    // shared.gridPoints.forEachCellThickness(selectedCellValue, (value) => {
    // console.log({thickness:value.thickness})
    // })
    // }
  }, [state.gridPointsConfig, selectedCellValue, shared.gridPoints])

  return (
    <div className="flex w-full">
      {state.selectedCell && selectedCellValue ? (
        <div className="flex w-full relative">
          <AScan scanId={scanId} fbkId={selectedCellValue.fbkIx}/>
          {ENABLE_REMOVE_INDEX && (
            <div className="absolute right-0 top-0 mt-4 mr-4">
              <Button
                size="sm"
                variant="secondary"
                onClick={() => removeIndex(selectedCellValue.fbkIx)}
              >
                Remove Index
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full bg-gray-100 border-gray-300 border rounded p-4">
          Select a cell to show detail information
        </div>
      )}
    </div>
  )
}

import React from "react"
import Skeleton from "react-loading-skeleton"
import Heading from "./Heading"
import { Scan, Media } from "../utils/scanService"
import Button from "./Button"
import Notes from "./Notes"
import FormattedDate from "./FormattedDate"

interface ViewImageAreaProps {
  data: Scan
  isLoading?: boolean
}

const ScanInformation: React.FC<ViewImageAreaProps> = ({ data, isLoading }) => {
  const {
    location,
    assetId,
    assetOwner,
    inspector,
    notes,
    site,
    images,
    audio,
    country,
  } = data || {}

  const metaData = {
    assetOwner,
    inspector,
    assetId,
  }

  const locationData = {
    location,
    site,
    country,
  }

  return (
    <div>
      <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8">
        <div className="w-full md:w-50 space-y-8">
          <div className="space-y-8 p-8  bg-white rounded shadow">
            <div className="flex justify-between">
              <Heading>Additional Data</Heading>
              <Button
                title="Not implemented"
                disabled
                variant="secondary"
                onClick={() => alert("Edit Dialog")}
              >
                Edit
              </Button>
            </div>
            {isLoading ? (
              <Skeleton count={4} />
            ) : (
              <div className="space-y-2">
                {Object.entries(metaData).map(([key, value]) => (
                  <div key={key} className="flex flex-col">
                    <Heading size="sm" muted>
                      {key}
                    </Heading>
                    <div>{Array.isArray(value) ? value.join(", ") : value}</div>
                  </div>
                ))}
              </div>
            )}
            {isLoading ? (
              <Skeleton />
            ) : (
              <div className="space-y-4">
                {images?.length > 0 && (
                  <div className="space-y-2">
                    <Heading size="sm" muted>
                      Images
                    </Heading>
                    <div className="grid grid-cols-3 gap-4">
                      {images.map((image: Media) => (
                        <a
                          href={image.src}
                          key={image.src}
                          className="space-y-2 p-2 rounded border hover:border-gray-500"
                        >
                          <img
                            src={image.src}
                            alt={`${image.date}`}
                            className="rounded"
                          />
                          <div className="text-xs text-muted">
                            <FormattedDate date={image.date} />
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
                {audio?.length > 0 && (
                  <div className="space-y-2">
                    <Heading size="sm" muted>
                      Audio
                    </Heading>
                    <div className="space-y-2">
                      {audio.map((file: Media) => (
                        <div key={file.src} className="flex flex-col">
                          <audio controls>
                            <source src={file.src} />
                          </audio>
                          <div className="text-xs text-muted">
                            <FormattedDate date={file.date} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="space-y-8 p-8  bg-white rounded shadow">
            <Notes isLoading={isLoading} notes={notes} />
          </div>
        </div>
        <div className="w-full md:w-50">
          <div className="space-y-8 p-8  bg-white rounded shadow">
            {isLoading ? (
              <div>
                <Skeleton height="400" />
                <Skeleton count={3} />
              </div>
            ) : (
              <div className="space-y-4">
                {/* <GoogleMap loc={location} /> */}
                <div className="space-y-2">
                  {Object.entries(locationData).map(([key, value]) => (
                    <div key={key} className="flex flex-col">
                      <Heading size="sm" muted>
                        {key}
                      </Heading>
                      <div>
                        {Array.isArray(value) ? value.join(", ") : value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScanInformation

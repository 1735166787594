import RangedColorMap from "../utils/RangedColorMap";
import GridPoints from "../api/scans/GridPoints";
import SurfaceMap from "../api/scans/SurfaceMap";
import {Cell} from "../providers/LocalStateProvider";

export default class GridPlot {

    private colorMap: RangedColorMap
    private grid: GridPoints;
    private surfaceMap?: SurfaceMap;
    private selectedCell?: Cell;
    private hoveredCell?: Cell;

    constructor(colorMap: RangedColorMap, grid: GridPoints, surfaceMap?: SurfaceMap, selectedCell?: Cell) {
        this.colorMap = colorMap
        this.grid = grid
        this.surfaceMap = surfaceMap
        this.selectedCell = selectedCell
        this.hoveredCell = undefined // TODO: should we show hovered cell as well?
    }

    public static clip(canvas: HTMLCanvasElement, surfaceMap: SurfaceMap) {
        const ctx = canvas.getContext("2d")
        if (ctx) {
            const uvRegion = surfaceMap.getEdgePath(canvas.width, canvas.height)
            ctx.clip(uvRegion, "evenodd")
        }
    }

    public drawOn(canvas: HTMLCanvasElement, drawTimeline: boolean, drawThicknessLabels: boolean): boolean {
        const ctx = canvas.getContext("2d")
        if (!ctx) {
            return false
        }

        const width = canvas.width
        const height = canvas.height
        GridPlot.drawBackground(ctx, width, height);
        this.drawRangeBorder(ctx, width, height);
        this.drawGridCells(ctx, width, height);
        this.drawGridLines(ctx, width, height);
        drawTimeline && this.drawTimeline(ctx, width, height)
        if (this.selectedCell) {
            this.drawCell(ctx, width, height, "#C82506", this.selectedCell)
        }
        if (this.hoveredCell) {
            this.drawCell(ctx,width, height,  "#c86a06", this.hoveredCell)
        }
        if (drawThicknessLabels) {
            this.drawThicknessLabels(ctx, width, height)
        }
        return true;

    }

    private static drawBackground(ctx: CanvasRenderingContext2D, width: number, height: number) {
        ctx.fillStyle = "#ffffff"
        ctx.fillRect(0, 0, width, height)
    }

    private drawRangeBorder(ctx: CanvasRenderingContext2D, width: number, height: number) {
        if (!this.surfaceMap){
            return
        }

        // Define Area in which we can draw
        const uvRegion = this.surfaceMap.getEdgePath(width, height)

        // Clip feasible area and draw a border
        ctx.lineWidth = 10
        ctx.strokeStyle = "rgba(140,140,140,0.3)"
        ctx.stroke(uvRegion)

    }

    private drawGridCells(ctx: CanvasRenderingContext2D, width: number, height: number){
        const blockSizeX = width / this.grid.getNumCols()
        const blockSizeY = height / this.grid.getNumRows()
        this.grid.forEach((row, col, thickness) => {
            //@ts-ignore why?
            ctx.fillStyle = this.colorMap.getColor(thickness)
            ctx.fillRect(col * blockSizeX, row * blockSizeY, blockSizeX, blockSizeY)
        })
    }

    private drawThicknessLabels(ctx: CanvasRenderingContext2D, width: number, height: number) {
        ctx.save()
        ctx.font = "12pt Sans-Serif";
        ctx.strokeStyle = 'gray';
        ctx.fillStyle = 'white'
        ctx.textAlign = "center";
        ctx.lineJoin = "round";
        ctx.lineWidth = 2;
        ctx.miterLimit = 2;

        let metrics = ctx.measureText('xx.x');
        const blockSizeX = width / this.grid.getNumCols()
        const blockSizeY = height / this.grid.getNumRows()
        if (metrics.width > blockSizeX - 6) {
            // too large to view on this resolution
            return
        }
        let offsetX = blockSizeX / 2
        let offsetY = blockSizeY / 2 + 4

        this.grid.forEach((row, col, thickness) => {
            if (thickness >= 0) {
                let x = col * blockSizeX + offsetX
                let y = row * blockSizeY + offsetY
                const text = thickness.toFixed(1) || '';
                // ctx.strokeText(text, x, y)
                ctx.fillText(text, x, y);
            }
        })
        ctx.restore()
    }

    private drawTimeline(ctx: CanvasRenderingContext2D, width: number, height: number) {
        ctx.beginPath()

        this.grid.forEachMotionPoint((x, y) => {
            ctx.lineTo(x*width, y*height)
        })

        ctx.lineWidth = 2
        ctx.strokeStyle = "rgba(180,180,180,0.5)"
        ctx.stroke()
    }

    private drawGridLines(ctx: CanvasRenderingContext2D, width: number, height: number) {
        ctx.beginPath()

        const blockSizeX = width / this.grid.getNumCols()
        const blockSizeY = height / this.grid.getNumRows()

        // Base Grid
        for (let x = 0; x <= width; x += blockSizeX) {
            ctx.moveTo(x, 0)
            ctx.lineTo(x, height)
        }

        for (let y = 0; y <= height; y += blockSizeY) {
            ctx.moveTo(0, y)
            ctx.lineTo(width, y)
        }

        ctx.lineWidth = 2
        ctx.strokeStyle = "rgba(140,140,140,0.3)"
        ctx.stroke()
    }

    private drawCell(ctx: CanvasRenderingContext2D, width: number, height: number, style:string, cell:Cell){
        if (!cell || !this.grid) return

        const blockSizeX = width / this.grid.getNumCols()
        const blockSizeY = height / this.grid.getNumRows()
        let x = cell.col * blockSizeX
        let y = cell.row * blockSizeY

        ctx.beginPath()
        ctx.moveTo(x, y)
        ctx.lineTo(x + blockSizeX, y)
        ctx.lineTo(x + blockSizeX, y + blockSizeY)
        ctx.lineTo(x, y + blockSizeY)
        ctx.lineTo(x, y)

        ctx.lineWidth = 3
        ctx.strokeStyle = style

        // show individual points
        ctx.fillStyle = ctx.strokeStyle
        this.grid.forEachCellUV(cell.row, cell.col, (x, y) => {
            ctx.fillRect(x * width - 1, y * height - 1, 3, 3)
        })
        ctx.stroke()
    }

}
import ACF from "../../utils/math/ACF";
import {Pose} from "./SharedTypes";

interface UtData {
  ascan: string;
  samplingRate?: number;
}

interface MapLocation {
  x: number;
  y: number;
  theta: number;
  distance: number;
}

interface JsonFeedback {
  sequence?: number;
  utData?: UtData;
  pose?: Pose;
  mapLocation?: MapLocation;
}

export default class Feedback {

  private readonly pose?: Pose
  private readonly ascan: Uint8Array
  private readonly acf: Float32Array
  private readonly samplingRate: number

  /**
   * Rectified A-Scan
   */
  public getAScan(): Uint8Array {
    return this.ascan
  }

  /**
   * Biased ACF of rectified and zero-meanead A-Scan
   */
  public getAcf(): Float32Array {
    return this.acf
  }

  public getSamplingRate(): number {
    return this.samplingRate
  }

  public getPose(): Pose | undefined {
    return this.pose
  }

  constructor(json: JsonFeedback) {
    if (json.utData?.ascan) {
      let base64 = json.utData.ascan
      let byteString = window.atob(base64)
      let numBytes = byteString.length
      let values = new Uint8Array(numBytes)
      const tmp = new Int8Array(1)
      for (var i = 0; i < numBytes; i++) {
        tmp[0] = byteString.charCodeAt(i)
        values[i] = Math.abs(tmp[0])
      }
      this.ascan = values
    } else {
      this.ascan = new Uint8Array(0)
    }
    this.acf = ACF.computeBiased(this.ascan);
    this.samplingRate = json.utData?.samplingRate || 100E6 // default 100 Mhz for now
    this.pose = json.pose
  }

}

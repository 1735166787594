import * as React from "react"
import { Slider } from "primereact/slider"
import { useLocalState } from "../providers/LocalStateProvider"
import { useSharedContext } from "../providers/SharedContextProvider"
import HistogramData, { Orientation } from "../utils/HistogramData"
import Heading from "./Heading"
import Button from "./Button"
import useDebounce from "../utils/useDebounce"

type HistoDataType = {
  min: number
  max: number
  values: number[]
}

// the slider has a minimum step size of 1, so to get
// sub-millimeter sliding, we need to scale the input
const RANGE_SCALE = 10;

export default function MinMaxSlider(): React.ReactElement {
  const { state: shared } = useSharedContext()
  const { state, setColorMapConfig, setGridPointsConfig } = useLocalState()
  const [localRange, setLocalRange] = React.useState<any>([
    Math.max(state.colorMapConfig.min || 0, shared.colorMap?.getMinBound()) * RANGE_SCALE,
    Math.min(state.colorMapConfig.max || Infinity, shared.colorMap?.getMaxBound()) * RANGE_SCALE,
  ])

  // Set local Range when color map changes e.g. gate change
  React.useEffect(() => {
    setLocalRange([
      Math.max(state.colorMapConfig.min || 0, shared.colorMap?.getMinBound()) * RANGE_SCALE,
      Math.min(state.colorMapConfig.max || Infinity, shared.colorMap?.getMaxBound()) * RANGE_SCALE,
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shared.colorMap])

  const onSetLocalRange = (e: any): void => {
    setLocalRange(e.value)
  }

  const onSetThicknessRange = (): void => {
    setGridPointsConfig({
      ...state.gridPointsConfig,
      minThickness: localRange[0] / RANGE_SCALE,
      maxThickness: localRange[1] / RANGE_SCALE,
    })
  }

  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  const histogram = new HistogramData(200)

  React.useEffect(() => {
    if (shared.gridPoints && shared.colorMap && canvasRef.current) {
      shared.gridPoints.setConfig(state.gridPointsConfig)
      shared.colorMap.setConfig(state.colorMapConfig)
      histogram.setRange(
        shared.gridPoints.getMinThickness(),
        shared.gridPoints.getMaxThickness()
      )
      shared.gridPoints.forEach((row, col, thickness) => {
        histogram.add(thickness)
      })

      histogram.drawOn(canvasRef.current, Orientation.Vertical, shared.colorMap, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.gridPointsConfig, state.colorMapConfig])

  // Delay change of color map for a bit as this operation is expensive.
  // Potentially replace with React Concurrent mode once its stable
  const debouncedRange = useDebounce(localRange, 20)

  React.useEffect(() => {
    setColorMapConfig({
      ...state.colorMapConfig,
      min: localRange[0] / RANGE_SCALE,
      max: localRange[1] / RANGE_SCALE,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRange])

  // TODO: combination of "flex" and "h-full" causes problems, so I removed "flex" for now
  return (
    <div className="border-r rounded-t space-y-2 h-full flex-col">
      <div className="p-2 flex space-x-1 flex-grow" ref={wrapperRef}>
        <Slider
          className="h-auto"
          orientation="vertical"
          min={shared.colorMap.getMinBound() * RANGE_SCALE}
          max={shared.colorMap.getMaxBound() * RANGE_SCALE}
          value={localRange}
          range
          onChange={onSetLocalRange}
        />
        {wrapperRef.current && (
          <canvas width={100} height={600} ref={canvasRef} />
        )}
      </div>
      <div className="p-2 border-t rounded-b flex justify-between space-x-2">
        <div>
          <Heading size="sm" muted>
            Min
          </Heading>
          <div className="text-xs">{localRange[0] / RANGE_SCALE}</div>
        </div>
        <div>
          <Heading size="sm" muted>
            Max
          </Heading>
          <div className="text-xs">{localRange[1] / RANGE_SCALE}</div>
        </div>
        <div className="flex-grow flex justify-end">
          <Button onClick={onSetThicknessRange} size="sm" variant="secondary">
            Filter
          </Button>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { queryCache } from "react-query"
import { Checkbox } from "primereact/checkbox"
import Heading from "./Heading"
import Button from "./Button"
import { useLocalState } from "../providers/LocalStateProvider"
import GateSettings from "./GateSettings"
import { getPoints } from "../utils/scanService"

interface GatesModalProps {
  close: () => void
  scanId: string
}

const GatesModal: React.FC<GatesModalProps> = ({ close, scanId }) => {
  const { state, setGatesConfig, setColorMapConfig } = useLocalState()

  const prefetchTodos = async (): Promise<any> => {
    setLoading(true)
    try {
      await queryCache.prefetchQuery(
        ["scanDetail", scanId, settings],
        getPoints
      )
    } catch (e) {
      console.log("Error!", e)
    }
    setLoading(false)
    changeGates()
    // The results of this query will be cached like a normal query
  }

  const changeGates = (): void => {
    setGatesConfig({
      ...state.gatesConfig,
      ...settings,
    })
    // Reset color map config now that our gates changed
    setColorMapConfig({
      ...state.colorMapConfig,
      min: undefined,
      max: undefined,
    })
    close()
  }

  const [settings, setSettings] = React.useState(
    state.gatesConfig || state.initialGatesConfig
  )
  const [loading, setLoading] = React.useState(false)

  return (
    <div className="space-y-4">
      <Heading>Change Gates</Heading>
      <p>Customize Gate values to meet your needs.</p>
      <GateSettings
        gate="A"
        values={settings.a}
        onChange={(val) =>
          setSettings({
            ...settings,
            a: {
              ...settings.a,
              ...val,
            },
          })
        }
      />
      <GateSettings
        values={settings.b}
        gate="B"
        onChange={(val) =>
          setSettings({
            ...settings,
            b: {
              ...settings.b,
              ...val,
            },
          })
        }
      />
      <div className="flex items-center space-x-2">
        <Checkbox
          inputId="isRelative"
          onChange={() =>
            setSettings({ ...settings, isRelative: !settings.isRelative })
          }
          checked={settings.isRelative}
        />
        <label htmlFor="isRelative" className="text-base font-normal">
          Relative
        </label>
      </div>

      <div className="space-x-2 flex">
        <Button size="lg" variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          loading={loading}
          size="lg"
          type="submit"
          onClick={prefetchTodos}
        >
          Create view
        </Button>
      </div>
    </div>
  )
}

export default GatesModal

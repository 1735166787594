import React from "react"
import useMouse from "@react-hook/mouse-position"
import {AnnotationObject} from "../api/scans/GridPoints"
import SurfaceMap from "../api/scans/SurfaceMap"
import {Cell, useLocalState} from "../providers/LocalStateProvider"
import {useSharedContext} from "../providers/SharedContextProvider"
import GridPlot from "../plots/GridPlot"

interface GeneratedImageProps {
  type?: "2d" | "3d"
  surfaceMap?: SurfaceMap
  annotations?: AnnotationObject[]
  setAnnotations?: (annotations: AnnotationObject[]) => void
}

const canvasWidth = 1200
const canvasHeight = 1200
const largeCanvasWidth = 3600
const largeCanvasHeight = 3600
const magnifierWidth = 400
const tooltipWidth = 50
const tooltipHeight = 25

const GeneratedImage: React.FC<GeneratedImageProps> = ({
  surfaceMap,
  annotations,
  setAnnotations,
}) => {
  const { setCanvas, state, setSelectedCell, setHoveredCell } = useLocalState()
  const { state: shared } = useSharedContext()
  const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 })
  const [showZoom, setShowZoom] = React.useState(false)
  const [hoverThickness, setHoverThickness] = React.useState('')
  const [selectedThickness, setSelectedThickness] = React.useState('')

  // Initialize all refs
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const overlayRef = React.useRef<HTMLDivElement>(null)
  const largerCanvasRef = React.useRef<HTMLCanvasElement>(null)

  const mouse = useMouse(canvasRef, {
    enterDelay: 100,
    leaveDelay: 100,
  })

  // Clipping is expensive and persists on the canvas, so we only need
  // to do it when the map changes or the whole plot gets redrawn
  React.useEffect(() => {
    if (canvasRef.current && shared.surfaceMap) {
      GridPlot.clip(canvasRef.current, shared.surfaceMap)
    }
  }, [canvasRef.current, shared.surfaceMap, shared.gridPoints])
  React.useEffect(() => {
    if (largerCanvasRef.current && shared.surfaceMap) {
      GridPlot.clip(largerCanvasRef.current, shared.surfaceMap)
    }
  }, [largerCanvasRef.current, shared.surfaceMap, shared.gridPoints])

  // Redraw canvas content
  React.useEffect(() => {
    if (shared.gridPoints && canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d")
      if (ctx) {
        shared.colorMap.setConfig(state.colorMapConfig)
        shared.gridPoints.setConfig(state.gridPointsConfig)

        // Draw grid plot
        const plot = new GridPlot(
          shared.colorMap,
          shared.gridPoints,
          shared.surfaceMap,
          state.selectedCell
        )

        if (
          plot.drawOn(canvasRef.current, state.gridPointsConfig.drawTimeline, false)
        ) {
          setCanvas(canvasRef.current)
        }

        if (largerCanvasRef.current && mouse.x) {
          plot.drawOn(largerCanvasRef.current, state.gridPointsConfig.drawTimeline, true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [
    state.colorMapConfig,
    state.gridPointsConfig,
    state.selectedCell,
    canvasRef.current,
    largerCanvasRef.current,
  ])

  React.useEffect(() => {
    if (mouse.x && mouse.y && canvasRef.current) {
      const x =
        largeCanvasWidth * (mouse.x / canvasRef.current.clientWidth) -
        magnifierWidth / 2
      const y =
        largeCanvasHeight * (mouse.y / canvasRef.current.clientHeight) -
        magnifierWidth / 2
      setMousePos({ x, y })
    }
  }, [mouse])

  function getColRow(x: number | null, y: number | null): Cell | null {
    if (canvasRef.current && x && y && shared.gridPoints) {
      const width = canvasRef.current.clientWidth
      const height = canvasRef.current.clientHeight
      const col = Math.floor((x / width) * shared.gridPoints.getNumCols())
      const row = Math.floor((y / height) * shared.gridPoints.getNumRows())
      return {
        col,
        row,
      }
    }
    return null
  }

  function onClickCell(
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ): void {
    const selectedCell = getColRow(e.nativeEvent.offsetX, e.nativeEvent.offsetY)

    if (
      state.selectedCell?.col === selectedCell?.col &&
      state.selectedCell?.row === selectedCell?.row
    ) {
      setSelectedCell(null)
    } else if (selectedCell) {
      setSelectedCell({
        col: selectedCell.col,
        row: selectedCell.row,
      })
    }
  }

  const handleKeyDown = (e: any): void => {
    if (state.selectedCell) {
      const { row, col } = state.selectedCell
      // Arrow Up
      if (e.keyCode === 38 && state.selectedCell.row > 0) {
        e.preventDefault()
        setSelectedCell({
          col,
          row: row - 1,
        })
        // Arrow Down
      } else if (e.keyCode === 40 && row < shared.gridPoints.getNumRows() - 1) {
        e.preventDefault()

        setSelectedCell({
          col,
          row: row + 1,
        })
      }
      // Arrow Left

      if (e.keyCode === 37 && col > 0) {
        e.preventDefault()

        setSelectedCell({
          row,
          col: col - 1,
        })
        // Arrow Right
      } else if (e.keyCode === 39 && col < shared.gridPoints.getNumCols() - 1) {
        e.preventDefault()

        setSelectedCell({
          row,
          col: col + 1,
        })
      }
    }
  }

  function onRightClick(
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ): void {
    e.preventDefault()
    setShowZoom(!showZoom)
  }

  React.useEffect(() => {
    const cell = getColRow(mouse.x, mouse.y)
    setHoveredCell(cell)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse])

  // Display in [mm] units
  React.useEffect(() => {
    if (state.hoveredCell && shared.gridPoints && state.gridPointsConfig) {
      shared.gridPoints.setConfig(state.gridPointsConfig)
      let {row, col} = state.hoveredCell
      setHoverThickness(shared.gridPoints.getCellValue(row, col)?.thickness?.toFixed(2) || '')
    } else {
      setHoverThickness('')
    }
  }, [state.hoveredCell, shared.gridPoints, state.gridPointsConfig])
  React.useEffect(() => {
    if (state.selectedCell && shared.gridPoints && state.gridPointsConfig) {
      shared.gridPoints.setConfig(state.gridPointsConfig)
      let {row, col} = state.selectedCell
      setSelectedThickness(shared.gridPoints.getCellValue(row, col)?.thickness?.toFixed(2) || '')
    } else {
      setSelectedThickness('')
    }
  }, [state.selectedCell, shared.gridPoints, state.gridPointsConfig])

  return (
    <div className="cursor-pointer">
      <div className="relative">
        <canvas
          tabIndex={0}
          onKeyDown={handleKeyDown}
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}
          className="w-full"
          onClick={onClickCell}
          onContextMenu={onRightClick}
        />

        {hoverThickness !== '' && (
          <div
            style={{
              fontSize: "12pt",
              padding: "2px",
              position: "absolute",
              width: tooltipWidth,
              height: tooltipHeight,
              left: 0,
              top: 0,
              background: "white",
              overflow: "hidden",
              borderRadius: "40%",
              textAlign: "center",
              fontWeight: "bold",
              verticalAlign: "middle",
              transform: `translate3d(${(mouse.x || 0) - tooltipWidth / 2}px, ${(mouse.y || 0) - tooltipHeight * 1.5}px, 0)`,
            }}>
            {hoverThickness}
          </div>
        )}

        {showZoom && mouse.x && (
          <div
            ref={overlayRef}
            style={{
              opacity: 1,
              position: "absolute",
              width: magnifierWidth,
              height: magnifierWidth,
              left: 0,
              top: 0,
              background: "white",
              overflow: "hidden",
              borderRadius: "100%",
              pointerEvents: "none",
              transition: "transform 10ms",
              transform: `translate3d(${
                (mouse.x || 0) - magnifierWidth / 2
              }px, ${(mouse.y || 0) - magnifierWidth / 2}px, 0)`,
            }}
          >
            <canvas
              ref={largerCanvasRef}
              width={largeCanvasWidth}
              height={largeCanvasHeight}
              style={{
                transform: `translate3d(${mousePos.x * -1}px, ${
                  mousePos.y * -1
                }px, 0)`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default GeneratedImage

import React from "react"
import { SelectButton } from "primereact/selectbutton"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { useQuery } from "react-query"
import { useParams, useHistory } from "react-router-dom"
import Button from "./Button"
import Modal from "./Modal"
import Heading from "./Heading"
import CustomizeModal from "./CustomizeModal"
import ExportModal from "./ExportModal"
import { SelectionMode } from "../api/scans/GridPoints"
import {
  useLocalState,
  defaultGridValues,
} from "../providers/LocalStateProvider"
import { useSharedContext } from "../providers/SharedContextProvider"
import SelectedScanArea from "./SelectedScanArea"
import GatesModal from "./GatesModal"
import HoverHistogram from "./HoverHistogram"
import Input from "./Input"
import { getProfile } from "../utils/scanService"
import SaveProfileModal from "./SaveProfileModal"
import ExportCsvButton from "./ExportCsvButton";

interface ViewCustomizationProps {
  scanId: string
}

const viewTypes = [
  {
    label: SelectionMode[SelectionMode.Coverage],
    value: SelectionMode.Coverage,
  },
  {
    label: SelectionMode[SelectionMode.Min],
    value: SelectionMode.Min,
  },
  {
    label: SelectionMode[SelectionMode.Median],
    value: SelectionMode.Median,
  },
  {
    label: SelectionMode[SelectionMode.Max],
    value: SelectionMode.Max,
  },
]

const gridSizes = defaultGridValues.map((val) => ({
  label: String(val),
  value: val,
}))

const ViewCustomization: React.FC<ViewCustomizationProps> = ({ scanId }) => {
  const {
    state,
    setGridPointsConfig,
    setProfile,
    setProfileData,
  } = useLocalState()
  const { state: shared } = useSharedContext()
  const { id, profile } = useParams()
  const history = useHistory()

  const { data, refetch } = useQuery(
    // @ts-ignore
    ["getProfile", id, state.selectedProfile],
    getProfile,
    { enabled: Boolean(state.selectedProfile) }
  )

  // Set Profile if URL contains /profile
  React.useEffect(() => {
    if (profile) {
      setProfile(profile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set Profile Data if new data is available
  React.useEffect(() => {
    if (data) setProfileData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // Refetch profile Data if selectedProfile changed
  React.useEffect(() => {
    if (state.selectedProfile) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedProfile])

  const setViewMode = (obj: any): void => {
    setGridPointsConfig({
      ...state.gridPointsConfig,
      ...obj,
    })
  }

  const onSetProfile = (value: string): void => {
    setProfile(value)
    history.push(`/scan/${id}/profile/${value}`)
  }

  const [showDialog, setShowDialog] = React.useState("")
  const openView = (): void => setShowDialog("view")
  const saveProfile = (): void => setShowDialog("saveProfile")
  const openGates = (): void => setShowDialog("gates")
  const openExport = (): void => setShowDialog("export")
  const togglePath = (): void =>
    setGridPointsConfig({
      ...state.gridPointsConfig,
      drawTimeline: !state.gridPointsConfig.drawTimeline,
    })
  const close = (): void => setShowDialog("")

  return (
    <div className="flex border-t flex-wrap flex-row justify-between">
      <div className="w-2/6 border-r border-gray-300 p-4 space-y-2">
        <div className="space-x-2 flex">
          <div>
            <Heading size="sm" muted>
              Total
            </Heading>
            <div className="text-xs">
              {shared.gridPoints.getTotalSampleCount()}
            </div>
          </div>
          <div>
            <Heading size="sm" muted>
              Shown
            </Heading>
            <div className="text-xs">
              {shared.gridPoints.getUsedSampleCount()}
            </div>
          </div>
          {state.hoveredCell && (
            <div>
              <Heading size="sm" muted>
                Hovered
              </Heading>
              <div className="text-xs">
                {shared.gridPoints.getCellCount(
                  state.hoveredCell.row,
                  state.hoveredCell.col
                )}
              </div>
            </div>
          )}
          {state.selectedCell && (
            <div>
              <Heading size="sm" muted>
                Selected
              </Heading>
              <div className="text-xs">
                {shared.gridPoints.getCellCount(
                  state.selectedCell.row,
                  state.selectedCell.col
                )}
              </div>
            </div>
          )}
        </div>
        <HoverHistogram />
      </div>

      <div className="w-4/6 lg:w-2/6 border-r border-gray-300 p-4 flex">
        {shared.surfaceMap && <SelectedScanArea scanId={scanId} />}
      </div>
      <div className="w-full lg:w-2/6 p-4 flex flex-col space-y-4 border-t lg:border-0">
        {/* PROFILE SELECTION */}
        <div className="space-x-2 lg:space-x-0 lg:space-y-2 flex flex-wrap justify-between">
          <div className="space-y-2 mb-2 w-full">
            <Heading size="sm">Profile</Heading>
            <div className="flex items-center space-x-6">
              <div className="flex-grow flex">
                <Dropdown
                  className="flex-grow"
                  value={state.selectedProfile}
                  options={shared.availableProfiles}
                  onChange={(e): void => {
                    onSetProfile(e.value)
                  }}
                />
                {state.profileChanged && (
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => alert("Reset")}
                  >
                    Edited
                  </Button>
                )}
              </div>
              <Button
                variant="secondary"
                onClick={saveProfile}
                title={
                  state.profileChanged ? "Save profile" : "Set values first"
                }
                disabled={!state.profileChanged}
              >
                Save Profile
              </Button>
            </div>
          </div>

          {/* VIEW MODE */}
          <div className="space-y-2 mb-2">
            <Heading size="sm">View Mode</Heading>
            <div className="flex items-center space-x-6">
              <SelectButton
                value={state.gridPointsConfig.selectionMode}
                options={viewTypes}
                onChange={(e): void => {
                  if (e.value !== null) {
                    setViewMode({ selectionMode: e.value })
                  }
                }}
              />
              <div className="flex items-center space-x-2">
                <Checkbox
                  inputId="drawTimeline"
                  onChange={togglePath}
                  checked={state.gridPointsConfig.drawTimeline}
                />
                <label htmlFor="drawTimeline" className="text-base font-normal">
                  Path
                </label>
              </div>
            </div>
          </div>

          {/* GRID SIZE */}
          <div className="space-y-2 mb-2">
            <Heading size="sm">Grid Size</Heading>
            <div className="flex space-x-4 flex-end">
              <SelectButton
                value={state.gridPointsConfig.gridSize}
                options={gridSizes}
                onChange={(e): void => {
                  if (e.value !== null) {
                    setViewMode({ gridSize: e.value })
                  }
                }}
              />
              <Input
                type="number"
                name="gridSize"
                min={1}
                max={99}
                value={state.gridPointsConfig.gridSize}
                onChange={(e): void => {
                  if (Number(e.target.value) > 0) {
                    setViewMode({ gridSize: Number(e.target.value) })
                  }
                }}
              />
            </div>
          </div>
        </div>

        {/* ACTION AREA */}
        <div className="space-x-2 flex items-end">
          <Button icon="pi-sliders-v" variant="secondary" onClick={openGates}>
            Gates
          </Button>
          <Button icon="pi-cog" variant="secondary" onClick={openView}>
            Distance
          </Button>
          {/*<Button
            icon="pi-external-link"
            variant="secondary"
            onClick={openExport}
            title="Not available yet"
          >
            Export
          </Button>*/}
          <ExportCsvButton/>
          <Button
            icon="pi-share-alt"
            variant="secondary"
            onClick={openExport}
            disabled
            title="Not available yet"
          >
            Share
          </Button>
        </div>
        <Modal
          ariaLabel="Changes Gates"
          isOpen={showDialog === "gates"}
          close={close}
        >
          <GatesModal close={close} scanId={scanId} />
        </Modal>
        <Modal
          ariaLabel="Distance Threshold"
          isOpen={showDialog === "view"}
          close={close}
        >
          <CustomizeModal close={close} />
        </Modal>
        <Modal
          ariaLabel="Choose export options"
          isOpen={showDialog === "export"}
          close={close}
        >
          <ExportModal close={close} selectedScans={[scanId]} />
        </Modal>
        <Modal
          ariaLabel="Save as Profile"
          isOpen={showDialog === "saveProfile"}
          close={close}
        >
          <SaveProfileModal close={close} />
        </Modal>
      </div>
    </div>
  )
}

export default ViewCustomization

import React, { useEffect } from "react"
import { useOktaAuth } from "@okta/okta-react"
import axios from "axios"

// Our hook
export default function useAuthInterceptor() {
  const { authState } = useOktaAuth()

  useEffect(() => {
    if (authState.isAuthenticated && authState.accessToken) {
      axios.interceptors.request.use((config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${authState.accessToken}`

        return config
      })
    }
  }, [authState]) // Update if authState changes
}

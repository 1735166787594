import React from "react"
import { useQuery } from "react-query"
import Skeleton from "react-loading-skeleton"
import { useOktaAuth } from "@okta/okta-react"
import Navbar from "../components/Navbar"
import Table from "../components/Table"
// @ts-ignore
import { getScans } from "../utils/scanService"
import ErrorScreen from "./ErrorScreen"

const Dashboard: React.FC = () => {
  // disables types until proper types land https://github.com/tannerlinsley/react-query/discussions/362
  // @ts-ignore
  const { isLoading, isError, data, error } = useQuery("scans", getScans)
  const { authState } = useOktaAuth()
  if (isError) {
    // @ts-ignore
    return <ErrorScreen>Error! {error.message}</ErrorScreen>
  }

  if (authState.isPending) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <Navbar />
      {isLoading ? (
        <div className="px-4">
          <Skeleton count={24} height={64} width="100%" />
        </div>
      ) : (
        data && <Table scans={data} />
      )}
    </div>
  )
}

export default Dashboard

import React from "react"
import { Slider } from "primereact/slider"
import Heading from "./Heading"
import Button from "./Button"
import { useLocalState } from "../providers/LocalStateProvider"
import { useSharedContext } from "../providers/SharedContextProvider"
import HistogramData, { Orientation } from "../utils/HistogramData"

interface CustomizeModalProps {
  close: () => void
}

const CustomizeModal: React.FC<CustomizeModalProps> = ({ close }) => {
  const { state, setGridPointsConfig } = useLocalState()
  const { state: shared } = useSharedContext()

  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  React.useEffect(() => {
    if (canvasRef.current) {
      const histogram = new HistogramData(100)

      histogram.setRange(
        shared.gridPoints.getMinDistance(),
        shared.gridPoints.getMaxDistance()
      )

      shared.gridPoints.forEachDistance((value) => {
        histogram.add(value)
      })

      histogram.drawOn(canvasRef.current, Orientation.Horizontal, shared.colorMap, true)
    }
  }, [shared.colorMap, shared.gridPoints])

  const onApply = (): void => {
    setGridPointsConfig({
      ...state.gridPointsConfig,
      maxDistance: distance / 1000,
    })
    close()
  }

  const [distance, setDistance] = React.useState(
    shared.gridPoints.getDistanceThreshold() * 1000
  )

  return (
    <div className="space-y-4">
      <Heading>Distance Threshold</Heading>
      <div className="space-y-2">
        <canvas className="w-full" width={800} ref={canvasRef} />
        <Slider
          min={shared.gridPoints.getMinDistance() * 1000}
          max={shared.gridPoints.getMaxDistance() * 1000}
          value={distance}
          onChange={(e: any) => {
            setDistance(e.value)
          }}
        />
        {distance}
      </div>
      <div className="space-x-2">
        <Button size="lg" variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button size="lg" type="submit" onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  )
}

export default CustomizeModal

import React from "react"
import ErrorScreen from "./ErrorScreen"

const Profile: React.FC = () => {

  return (
    <div className="py-8">
      <ErrorScreen>Page not found.</ErrorScreen>
    </div>
  )
}

export default Profile

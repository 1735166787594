import React from "react"
import Skeleton from "react-loading-skeleton"

type HeadingProps = {
  muted?: boolean
  type?: "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  size?: "sm" | "lg"
  isLoading?: boolean
}

const Heading: React.FC<HeadingProps> = ({
  children,
  type = "p",
  size,
  muted,
  isLoading = false,
}) => {
  const classNames = ["font-bold"]
  if (muted) classNames.push("text-muted")
  switch (size) {
    case "lg":
      classNames.push("h1")
      break
    case "sm":
      classNames.push("h6 uppercase")
      break
    default:
      classNames.push("h2")
      break
  }

  const Component = type

  return (
    <Component className={classNames.join(" ")}>
      {isLoading ? <Skeleton /> : children}
    </Component>
  )
}

export default Heading

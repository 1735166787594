import React from "react"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

interface DialogProps {
  isOpen?: boolean
  close?: () => void
  ariaLabel: string
}

const Modal: React.FC<DialogProps> = ({
  children,
  isOpen,
  close,
  ariaLabel,
}) => {
  return (
    <Dialog aria-label={ariaLabel} isOpen={isOpen} onDismiss={close}>
      {children}
    </Dialog>
  )
}

export default Modal

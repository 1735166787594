import React, { ReactElement } from "react"

type ButtonProps = {
  type?: "button" | "submit"
  variant?: "secondary" | "primary" | "ghost"
  size?: "sm" | "lg"
  title?: string
  onClick?: () => void
  disabled?: boolean
  icon?: string
  iconPosition?: "left" | "right"
  loading?: boolean
}

const Button: React.FC<ButtonProps> = ({
  type = "button",
  children,
  variant,
  size,
  title,
  onClick,
  disabled,
  icon,
  iconPosition = "left",
  loading,
}) => {
  const classNames = ["btn"]

  switch (variant) {
    case "secondary":
      classNames.push(
        "bg-white border border-gray-300 hover:bg-primary-100 hover:border-primary-500 text-primary-500"
      )
      break
    case "ghost":
      classNames.push("bg-gray-100 bg-opacity-10 hover:bg-opacity-25")
      break
    default:
      classNames.push("bg-primary-500 hover:bg-primary-600 text-white")
  }

  switch (size) {
    case "lg":
      classNames.push("px-6 py-3")
      break
    case "sm":
      classNames.push("px-2 py-1 text-sm")
      break
    default:
      classNames.push("px-4 py-2")
  }

  if (disabled) {
    classNames.push("opacity-50 cursor-not-allowed text-muted")
  }

  const iconTemplate = (): ReactElement =>
    loading ? (
      <div className="mr-1 flex items-center">
        <i className="pi pi-spin pi-spinner" />
      </div>
    ) : (
      <span
        className={`${
          iconPosition === "left" ? "mr-1" : "ml-1"
        } opacity-75 pi ${icon}`}
      />
    )

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
      title={title}
      className={classNames.join(" ")}
    >
      {icon && iconPosition === "left" && iconTemplate()}
      {!icon && loading ? (
        <div className="mr-1 flex items-center">
          <i className="pi pi-spin pi-spinner" />
        </div>
      ) : (
        children
      )}
      {icon && iconPosition === "right" && iconTemplate()}
    </button>
  )
}

export default Button

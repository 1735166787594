import * as React from "react"

interface DateProps {
  date: Date
}

const FormattedDate: React.FC<DateProps> = ({ date }) => {
  const outputDate = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(date))

  return <>{outputDate}</>
}

export default FormattedDate

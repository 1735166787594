import React from "react"
import Button from "./Button"
import Modal from "./Modal"
import { Scan } from "../utils/scanService"
import ExportModal from "./ExportModal"
import Heading from "./Heading"

interface ActionBarProps {
  selectedScans: Scan[]
  totalScans: number
}

const ActionBar: React.FC<ActionBarProps> = ({ selectedScans, totalScans }) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const open = (): void => setShowDialog(true)
  const close = (): void => setShowDialog(false)

  const selectedScanIds = selectedScans.map((scan) => scan.id)
  return (
    <div className="flex justify-between sticky top-0 z-10 py-2 bg-gray-100 px-4 items-center">
      <Heading size="sm">{totalScans} Scans</Heading>
      <div className="flex items-center">
        {selectedScans.length > 0 && (
          <div className="mr-2 font-bold">{selectedScans.length} selected</div>
        )}
        <Button
          disabled={selectedScans.length < 1}
          variant="secondary"
          onClick={open}
        >
          Export
        </Button>
        <Modal
          ariaLabel="Choose export options"
          isOpen={showDialog}
          close={close}
        >
          <ExportModal close={close} selectedScans={selectedScanIds} />
        </Modal>
      </div>
    </div>
  )
}

export default ActionBar

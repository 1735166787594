import React from "react"
import { useLocalState, Cell } from "../providers/LocalStateProvider"
import { useSharedContext } from "../providers/SharedContextProvider"
import HistogramData, { Orientation } from "../utils/HistogramData"

export default function HoverHistogram() {
  const { state } = useLocalState()
  const { state: shared } = useSharedContext()

  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  const histogram = new HistogramData(100)

  React.useEffect(() => {
    if (canvasRef.current) {
      shared.colorMap.setConfig(state.colorMapConfig)
      shared.gridPoints.setConfig(state.gridPointsConfig)

      histogram.setRange(
        shared.gridPoints.getMinThickness(),
        shared.gridPoints.getMaxThickness()
      )

      let cell: Cell | undefined | null
      if (state.hoveredCell) {
        cell = state.hoveredCell
      } else if (state.selectedCell) {
        cell = state.selectedCell
      }
      if (!cell) return

      shared.gridPoints.forEachCellThickness(
        cell.row,
        cell.col,
        (thickness) => {
          histogram.add(thickness)
        }
      )

      histogram.drawOn(
        canvasRef.current,
        Orientation.Horizontal,
        shared.colorMap
      )
    }
  }, [
    histogram,
    shared.colorMap,
    shared.gridPoints,
    state.colorMapConfig,
    state.gridPointsConfig,
    state.hoveredCell,
    state.selectedCell,
  ])

  return (
    <div>
      {state.hoveredCell || state.selectedCell ? (
        <canvas ref={canvasRef} />
      ) : (
        <div className="w-full bg-gray-100 border-gray-300 border rounded p-4">
          Hover over a cell to see its information
        </div>
      )}
    </div>
  )
}

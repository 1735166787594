import React, { ReactElement } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react"
import Dashboard from "../views/Dashboard"
import Detail from "../views/Detail"
import Profile from "../views/Profile"
import NoMatchPage from "../views/NoMatchPage"

export default function AppRouter(): ReactElement | null {
  return (
    <Router>
      <Security
        issuer={`${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`}
        clientId={process.env.REACT_APP_OKTA_CLIENT_ID}
        redirectUri={`${window.location.origin}/implicit/callback`}
        scopes={["profile", "openid", "email", "groups"]}
        pkce
      >
        <Switch>
          <SecureRoute exact path="/" component={Dashboard} />
          <Route exact path="/scan/:id" component={Detail} />
          <Route exact path="/scan/:id/profile/:profile" component={Detail} />
          <SecureRoute exact path="/profile" component={Profile} />
          <Route path="/implicit/callback" component={LoginCallback} />
          <Route component={NoMatchPage} />
        </Switch>
      </Security>
    </Router>
  )
}

import {GateAlgorithm, GateConfig, GateLocation, GateResult, GateResults} from "../../api/scans/SharedTypes";
import {NumberArray} from "../ArrayUtils";
import Feedback from "../../api/scans/Feedback";


export function computeGateResults(fbk: Feedback, config: GateConfig): GateResults | undefined {

  let a;
  const signal = fbk.getAScan();
  switch (config.algorithm) {

    case GateAlgorithm.Standard:
      a = applyGate(signal, config.a)
      if (a) {
        return {a, thicknessSamples: a.refPos}
      }
      break;

    case GateAlgorithm.EchoToEcho:
      a = applyGate(signal, config.a)
      if (a) {
        let b = applyGate(signal, config.b, config.isRelative ? a.refPos : 0)
        if (b) {
          return {
            a, b, thicknessSamples: Math.abs(b.refPos - a.refPos)
          }
        }
      }
      break;

    case GateAlgorithm.Autocorrelation:
      if (config.acf) {
        // Make sure that the signal isn't just noise
        a = applyGate(signal, {start: 0, range: signal.length, level: config.acf.level})
        if (a) {
          let acf = applyGate(fbk.getAcf(), {
            ...config.acf,
            level: 0
          })

          // TODO: do we still need the filter based on the ratio?
          if (acf) {
            return {
              a, thicknessSamples: acf.maxPos
            }
          }

        }
      }


      break;
  }

  return undefined

}

function applyGate(signal: NumberArray, gate?: GateLocation, offset?: number): GateResult | undefined {
  if (!gate) {
    return undefined
  }
  const off = offset ?? 0
  const startIx = Math.max(0, off + gate.start)
  const endIx = Math.min(off + gate.start + gate.range, signal.length)

  let refPos = -1;
  let maxPos = -1;
  let maxValue = 0;

  for (let i = startIx; i < endIx; i++) {
    const value = Math.abs(signal[i]);
    if (value >= gate.level) {
      if (refPos == -1) {
        refPos = i
        maxPos = i
        maxValue = value
      } else if (value > maxValue) {
        maxPos = i
        maxValue = value
      }
    }
  }

  if (refPos >= 0) {
    return {
      refPos,
      maxPos,
      maxValue
    }
  }

}

import React, { ChangeEvent } from "react"

type InputProps = {
  type?: "text" | "number"
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  value?: string | number
  name: string
  label?: string
  min?: number
  max?: number
  step?: number
}

const Input: React.FC<InputProps> = ({
  type = "button",
  value,
  name,
  onChange,
  disabled,
  label,
  min,
  max,
  step,
}) => {
  const classNames = ["input"]

  if (disabled) {
    classNames.push("opacity-50 cursor-not-allowed text-muted")
  }

  return (
    <div className="flex flex-col">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        name={name}
        disabled={disabled}
        onChange={onChange}
        value={value}
        min={min}
        max={max}
        step={step}
        // eslint-disable-nEext-line react/button-has-type
        type={type}
        className={classNames.join(" ")}
      />
    </div>
  )
}

export default Input

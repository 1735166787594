/**
 * Statistic mode by which a one value within a
 * cell gets selected
 */
import ArrayUtils, {UnsignedArray} from "../../utils/ArrayUtils"
import {GateConfig} from "./SharedTypes"

export enum SelectionMode {
  Coverage,
  Min,
  Median,
  Max,
}

/**
 * Value representing points in a single grid cell
 */
export interface CellValue {
  row: number
  col: number
  fbkIx: number
  thickness: number
  startIx: number
  endIx: number
}

// TODO: move annotations somewhere else
export interface AnnotationObject {
  selection?: object
  geometry?: {
    type: string
  }
  data?: {
    id?: number
    text?: string
  }
}

/**
 * Raw JSON response. Should not be used directly
 */
export interface JsonPoints {
  hasUtData: boolean
  hasThicknessSamples: boolean
  numTotalPoints: number
  numIncludedPoints: number
  map: MapRange
  points: Points
  gates: GateConfig

  annotations?: AnnotationObject[]
}

export interface GridPointsConfig {
  gridSize?: number
  selectionMode?: SelectionMode
  drawTimeline: boolean
  maxDistance?: number
  minThickness?: number
  maxThickness?: number
}

interface Points {
  // Individual points
  msgIndex: number[]
  x: number[]
  y: number[]
  theta: number[]
  thicknessSamples: number[]
  distance: number[]

  // Summary statistics
  thicknessSamplesMin: number
  thicknessSamplesMax: number
  distanceMax: number
}

interface MapRange {
  xMin: number
  xMax: number
  yMin: number
  yMax: number
}

/**
 * Wraps the JSON return message and exposes
 * the data in a grid form
 */
export default class GridPoints {
  public getGates(): GateConfig {
    return this.gates
  }

  public getTotalSampleCount(): number {
    return this.numTotalPoints
  }

  public getUsedSampleCount() {
    return this.numIndices
  }

  public getCellCount(row: number, col: number): number {
    const cell = this.getCellValue(row, col)
    return cell ? cell.endIx - cell.startIx : 0
  }

  public setConfig(config: GridPointsConfig): void {
    if (!config) {
      return
    }

    if (
      config.gridSize !== undefined &&
      config.gridSize / 1000 !== this.resolution
    ) {
      this.resolution = config.gridSize / 1000
      this.dirtyGrid = true
    }
    if (
      config.maxDistance !== undefined &&
      config.maxDistance !== this.maxDistance
    ) {
      this.maxDistance = config.maxDistance
      this.dirtyGrid = true
    }
    if (
      config.minThickness !== undefined &&
      config.minThickness !== this.minThickness
    ) {
      this.minThickness = config.minThickness
      this.dirtyGrid = true
    }
    if (
      config.maxThickness !== undefined &&
      config.maxThickness !== this.maxThickness
    ) {
      this.maxThickness = config.maxThickness
      this.dirtyGrid = true
    }
    if (
      config.selectionMode !== undefined &&
      config.selectionMode !== this.selectionMode
    ) {
      this.selectionMode = config.selectionMode
      this.dirtyMode = true
    }

    // Reset cached state
    if (this.dirtyGrid || this.dirtyMode) {
      this.cachedCell = undefined
    }
  }

  /**
   * Can be non-integer value
   */
  public getNumRows(): number {
    return this.rangeY / this.resolution
  }

  /**
   * Can be non-integer value
   */
  public getNumCols(): number {
    return this.rangeX / this.resolution
  }

  public getMinThickness(): number {
    return Math.floor(this.thicknessSamplesMin * this.samplesToThickness)
  }

  public getMaxThickness(): number {
    return Math.ceil(this.thicknessSamplesMax * this.samplesToThickness)
  }

  public getMinDistance(): number {
    return 0
  }

  public getMaxDistance(): number {
    return this.distanceMax
  }

  public getDistanceThreshold(): number {
    return this.maxDistance
  }

  // ============== CONSTANT: Time-Sorted Motion in X/Y ==============
  public forEachMotionPoint(consumer: (x: number, y: number) => void) {
    // Message indices are ordered by time. The indices never change,
    // so we can store it forever
    if (!this.indicesSortedByTime) {
      const indices = this.msgIndex.slice()
      for (let i = 0; i < indices.length; i++) {
        indices[i] = i
      }
      indices.sort((a, b) => this.msgIndex[b] - this.msgIndex[a])
      this.indicesSortedByTime = indices
    }

    // Access based on time sorted indices
    const indices = this.indicesSortedByTime
    for (let i = 0; i < indices.length; i++) {
      const ix = indices[i]
      if (this.isValidIndex(ix)) {
        consumer(this.x[ix] / this.rangeX, this.y[ix] / this.rangeY)
      }
    }
  }

  // ============== CONSTANT: Distance from X/Y surface plane ==============
  public forEachDistance(consumer: (distance: number) => void) {
    for (let i = 0; i < this.distance.length; i++) {
      consumer(this.distance[i])
    }
  }

  // ============== DYNAMIC: Iterates over the value in each grid cell ==============
  public forEach(
    consumer: (row: number, col: number, thickness: number) => void
  ): void {
    this.computeGrid()
    for (let i = 0; i < this.numSelected; i++) {
      const v = this.selectedValues[i]
      consumer(v.row, v.col, v.thickness)
    }
  }

  // ============== DYNAMIC: Iterates over the values inside one specific grid cell ==============
  public forEachCellThickness(
    row: number,
    col: number,
    consumer: (thickness: number) => void
  ) {
    const cell = this.getCellValue(row, col)
    if (cell) {
      const {startIx, endIx} = cell
      for (let ix = startIx; ix < endIx; ix++) {
        consumer(this.thickness[this.indices[ix]])
      }
    }
  }

  public forEachCellUV(
    row: number,
    col: number,
    consumer: (x: number, y: number) => void
  ) {
    const cell = this.getCellValue(row, col)
    if (cell) {
      const {startIx, endIx} = cell
      for (let ix = startIx; ix < endIx; ix++) {
        const i = this.indices[ix]
        consumer(this.x[i] / this.rangeX, this.y[i] / this.rangeY)
      }
    }
  }

  private cachedCell: CellValue | undefined

  public getCellValue(row: number, col: number): CellValue | null {
    // Usually we hover/select the same cell, so cache the previous value
    if (
      this.cachedCell &&
      this.cachedCell.row === row &&
      this.cachedCell.col === col
    ) {
      return this.cachedCell
    }

    // Otherwise search for cell
    const index = ArrayUtils.binarySearch(0, this.numSelected, (ix) => {
      const rowDiff = this.selectedValues[ix].row - row
      if (rowDiff !== 0) {
        return rowDiff
      }
      const colDiff = this.selectedValues[ix].col - col
      if (colDiff !== 0) {
        return colDiff
      }
      return 0
    })
    if (index) {
      this.cachedCell = this.selectedValues[index]
      return this.cachedCell
    }
    return null
  }

  // ============== Internal Class State ==============
  private readonly gates: GateConfig

  private readonly numTotalPoints: number

  private readonly rangeX: number

  private readonly rangeY: number

  private readonly thicknessSamplesMin: number

  private readonly thicknessSamplesMax: number

  private readonly distanceMax: number

  private readonly msgIndex: UnsignedArray

  private readonly x: Float32Array | number[]

  private readonly y: Float32Array | number[]

  private readonly distance: Float32Array | number[]

  // TODO: add material velocity to config and maybe send thicknessTime?
  private readonly sampleRate = 100E6 // 100 Mhz TODO: get from server
  private readonly materialVelocity_steel4340 = 5850 * 1000 // mm/sec TODO: make configurable
  private readonly samplesToThickness = this.materialVelocity_steel4340 / this.sampleRate / 2; // divide by two because it's round-trip
  private readonly thickness: Float32Array // actual thickness in [mm]

  private readonly selectedValues: CellValue[]

  private numSelected: number

  private readonly indices: UnsignedArray // array used for sorting

  private numIndices: number // number of indices that are deemed valid

  private indicesSortedByTime: UnsignedArray | undefined

  private tmpIndexArray: UnsignedArray

  private selectionMode: SelectionMode

  private resolution: number

  private maxDistance: number

  private minThickness: number

  private maxThickness: number

  private dirtyGrid: boolean

  private dirtyMode: boolean

  constructor(json: JsonPoints) {
    const {map, gates} = json
    const offsetX = map.xMin
    const offsetY = map.yMin
    this.rangeX = map.xMax - map.xMin
    this.rangeY = map.yMax - map.yMin
    this.gates = gates

    // Summary statistics
    this.distanceMax = json.points.distanceMax
    // this.thicknessSamplesMin = json.points.thicknessSamplesMin
    this.thicknessSamplesMin = 0; // ColorMap range needs to allow for zero
    this.thicknessSamplesMax = json.points.thicknessSamplesMax
    this.numTotalPoints = json.numTotalPoints

    const maxMsgValue = json.numTotalPoints
    const maxThicknessSamplesValue = Math.max(
      Math.abs(this.thicknessSamplesMin),
      Math.abs(this.thicknessSamplesMax)
    )

    // Select the smallest array possible to hold the selected value range
    this.msgIndex = ArrayUtils.createUnsignedFrom(
      maxMsgValue,
      json.points.msgIndex
    )

    // Values in the protocol are Float32, so no need to go to double
    this.distance = Float32Array.from(json.points.distance)
    const x = Float32Array.from(json.points.x)
    const y = Float32Array.from(json.points.y)
    if (offsetX !== 0 || offsetY !== 0) {
      for (let i = 0; i < x.length; i++) {
        x[i] -= offsetX
        y[i] -= offsetY
      }
    }
    this.x = x
    this.y = y

    // Internal state
    this.selectionMode = SelectionMode.Min
    this.resolution = 0.005
    this.maxDistance = 0.02
    this.dirtyGrid = true
    this.dirtyMode = true
    this.selectedValues = []
    this.numSelected = 0
    this.tmpIndexArray = new Uint16Array(0)
    this.numIndices = json.numIncludedPoints
    this.indices = ArrayUtils.createIndexRange(0, this.numIndices)

    // =================== Integrated mode w/ A-Scan data ===================

    if (json.hasUtData) {
      let thicknessSamples = ArrayUtils.createSignedFrom(
        maxThicknessSamplesValue,
        json.points.thicknessSamples
      )

      // Convert to actual thickness.
      this.thickness = new Float32Array(thicknessSamples.length);
      for (let i = 0; i < this.thickness.length; i++) {
        this.thickness[i] = thicknessSamples[i] * this.samplesToThickness;
      }

      this.minThickness = this.thicknessSamplesMin * this.samplesToThickness
      this.maxThickness = this.thicknessSamplesMax * this.samplesToThickness

    } else {
      // TODO: remove fallback
      this.minThickness = 0;
      this.maxThickness = 1;
      this.thickness = new Float32Array(this.x.length);
      this.thickness.fill(0.5);
    }

  }

  private isValidIndex(index: number) {
    return (
      this.distance[index] <= this.maxDistance &&
      this.thickness[index] >= this.minThickness &&
      this.thickness[index] <= this.maxThickness
    )
  }

  private computeRow(i: number): number {
    return Math.floor(this.y[i] / this.resolution)
  }

  private computeCol(i: number): number {
    return Math.floor(this.x[i] / this.resolution)
  }

  // The data is sorted by raw x/y, so we need to sort into actual cells
  private sortBucketizedIndices() {
    this.indices.sort((ix0, ix1) => {
      // Move invalid points out of the way
      const isValid0 = this.isValidIndex(ix0)
      const isValid1 = this.isValidIndex(ix1)
      if (isValid0 !== isValid1) {
        return isValid0 ? -1 : 1
      }

      // Sort by row
      const row0 = this.computeRow(ix0)
      const row1 = this.computeRow(ix1)
      if (row0 !== row1) {
        return row0 - row1
      }

      // Sort by column
      const col0 = this.computeCol(ix0)
      const col1 = this.computeCol(ix1)
      if (col0 !== col1) {
        return col0 - col1
      }

      // Sort by thickness
      return this.thickness[ix0] - this.thickness[ix1]
    })

    // Set numIndices to include all valid points
    for (let i = this.indices.length - 1; i >= 0; i--) {
      if (this.isValidIndex(this.indices[i])) {
        this.numIndices = i + 1
        return
      }
    }
  }

  private computeGrid(): void {
    if (this.dirtyGrid) {
      this.sortBucketizedIndices()
      const {indices} = this
      const numElements = this.numIndices

      let numPoints = 0

      // Current row/col
      let startIx = 0
      let row = this.computeRow(indices[startIx])
      let col = this.computeCol(indices[startIx])
      let endIx
      let nextRow = row
      let nextCol = col
      while (startIx < numElements) {
        // Expand range until endIx is located in a different grid cell
        for (endIx = startIx + 1; endIx < numElements; endIx++) {
          nextRow = this.computeRow(indices[endIx])
          nextCol = this.computeCol(indices[endIx])
          if (nextRow !== row || nextCol !== col) {
            break
          }
        }

        // Find the appropriate value with the data subset. The data is already sorted
        const i = numPoints++
        if (numPoints <= this.selectedValues.length) {
          // Reuse existing elements if possible
          this.selectedValues[i].startIx = startIx
          this.selectedValues[i].endIx = endIx
          this.selectedValues[i].row = row
          this.selectedValues[i].col = col
        } else {
          // Allocate a new element if necessary
          this.selectedValues.push({
            startIx,
            endIx,
            row,
            col,
            fbkIx: 0,
            thickness: 0,
          })
        }

        // Prepare next
        startIx = endIx
        row = nextRow
        col = nextCol
      }
      this.numSelected = numPoints
      this.dirtyGrid = false
      this.dirtyMode = true
    }

    if (this.dirtyMode) {
      for (let i = 0; i < this.numSelected; i++) {
        const {startIx, endIx} = this.selectedValues[i]
        const rawIx = this.computeSelectedIndex(startIx, endIx)
        this.selectedValues[i].fbkIx = this.msgIndex[rawIx]
        this.selectedValues[i].thickness = this.thickness[rawIx]
      }
      this.dirtyMode = false
    }
  }

  // endIx exclusive, i.e., i < endIx
  private computeSelectedIndex(startIx: number, endIx: number): number {
    switch (this.selectionMode) {
      case SelectionMode.Min:
        return this.indices[startIx]
      case SelectionMode.Max:
        return this.indices[endIx - 1]
      case SelectionMode.Median:
        const halfLength = Math.floor((endIx - startIx) / 2)
        return this.indices[startIx + halfLength]
      case SelectionMode.Coverage:
        return 1
      default:
        throw new Error("Not implemented")
    }
  }
}

import * as React from "react"
import parseISO8601TimePattern from "../utils/DateFormat"

interface DurationProps {
  duration: string
}

const FormattedDuration: React.FC<DurationProps> = ({ duration }) => {
  const { minutes, hours, seconds } = parseISO8601TimePattern(duration)

  const outputSec = Math.round(seconds)

  const outputDuration = [
    hours > 0 ? `${hours} h` : "",
    minutes > 0 ? `${minutes} m` : "",
    outputSec > 0 ? `${outputSec} s` : "",
  ].join(" ")

  return <>{outputDuration}</>
}

export default FormattedDuration

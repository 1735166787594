export interface GateResult {
  refPos: number
  maxPos: number
  maxValue: number
}

export interface GateResults {
  a: GateResult
  b?: GateResult
  thicknessSamples: number
}

export interface GateLocation {
  start: number
  range: number
  level: number
}

export enum GateAlgorithm {
  EchoToEcho = 0, // Two gates; distance between detected crossings in these gates
  MockFunction = 1, // Ascan is from log, gate results are from a function of (x,y)
  Autocorrelation = 2, // Auto-correlation approach
  Standard = 3, // "industry standard": ref pos is at the first edge that crosses the gate
}

export interface GateConfig {
  algorithm?: GateAlgorithm
  a?: GateLocation
  b?: GateLocation
  acf?: GateLocation
  acf_min_ratio?: number
  isRelative?: boolean
}

export interface Pose {
  delay?: number;
  position?: Vec3;
  orientation?: Quat;
}

export interface Vec3 {
  x: number;
  y: number;
  z: number;
}

export interface Quat {
  w: number;
  x: number;
  y: number;
  z: number;
}
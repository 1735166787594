import React from "react"
import { SkeletonTheme } from "react-loading-skeleton"
import { ReactQueryConfigProvider } from "react-query"
import AppRouter from "./routes"
import "primereact/resources/primereact.css"
import "primeicons/primeicons.css"
import "./css/prime-override.css"
import { ViewStateProvider } from "./providers/ViewStateProvider"
import { LocalStateProvider } from "./providers/LocalStateProvider"
import { SharedContextProvider } from "./providers/SharedContextProvider"

const queryConfig = { queries: { refetchOnWindowFocus: false } }

export default function App(): React.ReactElement {
  return (
    <SkeletonTheme color="#f1f5f8" highlightColor="#f8fafc">
      {/* @ts-ignore */}
      <ReactQueryConfigProvider config={queryConfig}>
        <SharedContextProvider>
          <LocalStateProvider>
            <ViewStateProvider>
              <AppRouter />
            </ViewStateProvider>
          </LocalStateProvider>
        </SharedContextProvider>
      </ReactQueryConfigProvider>
    </SkeletonTheme>
  )
}

import React from "react"
// @ts-ignore
import { useHistory, Link } from "react-router-dom"
import { useOktaAuth } from "@okta/okta-react"
import UserInfo from "./UserInfo"
import Button from "./Button"

interface HeaderProps {
  backLink?: boolean
}

const Header: React.FC<HeaderProps> = ({ backLink = false }) => {
  const history = useHistory()
  const { authState, authService } = useOktaAuth()

  return (
    <div className="bg-gray-900 text-gray-100 py-4 border-b-4 border-gray-700">
      <div className="px-4 flex justify-between items-center space-x-2">
        <div className="flex items-center space-x-2">
          {backLink && (
            <Button
              icon="pi-angle-left"
              variant="ghost"
              onClick={(): void => history.push("/")}
            >
              Back to Overview
            </Button>
          )}
          <Link to="/">NDT Cloud</Link>
        </div>

        <div className="ml-auto flex items-center space-x-4">
          <UserInfo />
          <div>
            {authState.isAuthenticated ? (
              <Button
                variant="ghost"
                onClick={(): void => {
                  authService.logout()
                }}
              >
                Logout
              </Button>
            ) : (
              <Button
                variant="ghost"
                onClick={(): void => {
                  authService.login()
                }}
              >
                Login
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header

import React from "react"
import GridPoints from "../api/scans/GridPoints"
import RangedColorMap from "../utils/RangedColorMap"
import SurfaceMap from "../api/scans/SurfaceMap"

export interface Profile {
  label: string
  value: string
}

type SharedContext = {
  gridPoints: GridPoints
  colorMap: RangedColorMap
  surfaceMap: SurfaceMap
  availableProfiles: [Profile]
}

type State = {
  state: SharedContext
  setGridPoints: (data: GridPoints) => void
  setSurfaceMap: (data: SurfaceMap) => void
  setAvailableProfiles: (data: Profile[]) => void
  resetSharedContext: () => void
}

enum Action {
  SetGridPoints,
  SetSurfaceMap,
  SetAvailableProfiles,
  ResetSharedContext,
}

const SharedContext = React.createContext<State | undefined>(undefined)

const defaultState = {
  gridPoints: null,
  colorMap: null,
  surfaceMap: null,
  availableProfiles: [],
}

function stateReducer(state: any, action: any) {
  switch (action.type) {
    case Action.SetGridPoints:
      return {
        ...state,
        gridPoints: action.data,
        availableProfiles: [],
        colorMap: new RangedColorMap(
          action.data.getMinThickness(),
          action.data.getMaxThickness()
        ),
      }
    case Action.SetSurfaceMap:
      return {
        ...state,
        surfaceMap: action.data,
      }
    case Action.SetAvailableProfiles:
      return {
        ...state,
        availableProfiles: action.data,
      }
    case Action.ResetSharedContext:
      return defaultState
    default:
      throw new Error("Not implemented.")
  }
}

function SharedContextProvider({ children }: { children: React.ReactElement }) {
  const [state, dispatch] = React.useReducer(stateReducer, defaultState)

  const sharedContext = React.useMemo(
    () => ({
      state,
      setGridPoints: (data: GridPoints) => {
        dispatch({ type: Action.SetGridPoints, data })
      },
      setSurfaceMap: (data: SurfaceMap) => {
        dispatch({ type: Action.SetSurfaceMap, data })
      },
      setAvailableProfiles: (data: Profile[]) => {
        dispatch({ type: Action.SetAvailableProfiles, data })
      },
      resetSharedContext: () => {
        dispatch({ type: Action.ResetSharedContext })
      },
    }),
    [state]
  )

  return (
    <SharedContext.Provider value={sharedContext}>
      {children}
    </SharedContext.Provider>
  )
}

function useSharedContext() {
  const context = React.useContext(SharedContext)
  if (context === undefined) {
    throw new Error(
      "useSharedContext must be used within a SharedContextProvider"
    )
  }
  return context
}

export { SharedContextProvider, useSharedContext, SharedContext }

export type UnsignedArray = Uint16Array | Uint32Array
export type SignedArray = Int16Array | Int32Array

export type NumberArray = number[]
  | Int8Array | Uint8Array
  | Int16Array | Uint16Array
  | Int32Array | Uint32Array
  | Float32Array | Float64Array;

export default class ArrayUtils {

  private static readonly MAX_INT_16: 32767
  private static readonly MAX_UINT_16: 65536

  static createIndexRange(startIx: number, endIx: number): UnsignedArray {
    const length = endIx - startIx
    const array = ArrayUtils.createUnsignedArray(length, endIx)
    for (let i = 0; i < length; i++) {
      array[i] = i + startIx
    }
    return array;
  }

  static createUnsignedArray(length: number, maxValue: number): UnsignedArray {
    if (maxValue <= ArrayUtils.MAX_UINT_16) {
      return new Uint16Array(length)
    } else {
      return new Uint32Array(length)
    }
  }

  static createUnsignedFrom(maxValue: number, values: number[]): UnsignedArray {
    if (Math.abs(maxValue) <= this.MAX_UINT_16) {
      return Uint16Array.from(values)
    } else {
      return Uint32Array.from(values)
    }
  }

  static createSignedArray(length: number, maxValue: number): SignedArray {
    if (Math.abs(maxValue) <= this.MAX_INT_16) {
      return new Int16Array(length)
    } else {
      return new Int32Array(length)
    }
  }

  static createSignedFrom(maxValue: number, values: number[]): SignedArray {
    if (Math.abs(maxValue) <= this.MAX_INT_16) {
      return Int16Array.from(values)
    } else {
      return Int32Array.from(values)
    }
  }

  static linearSearch(startIx: number, endIx: number, compare: (ix: number) => number): number | undefined {
    let count = 0
    for (let i = startIx; i < endIx; i++) {
      count += 1
      if (compare(i) === 0) {
        return i
      }
    }
    return undefined
  }

  static binarySearch(startIx: number, endIx: number, compare: (ix: number) => number) : number | undefined {
    let count = 0
    let start = startIx
    let end = endIx - 1
    while (start <= end) {

      // Find the mid index
      count += 1
      const mid = Math.floor((start + end) / 2)
      const res = compare(mid)

      // Found element
      if (res === 0) {
        return mid
      }

      // Advance to left or right half
      if (res < 0) {
        start = mid + 1
      } else {
        end = mid - 1
      }

    }

    // Give up
    return undefined
  }

}
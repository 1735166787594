import Button from "./Button";
import * as React from "react";
import {useSharedContext} from "../providers/SharedContextProvider";
import {useLocalState} from "../providers/LocalStateProvider";

export default function ExportCsvButton(): React.ReactElement {
  const {state: shared} = useSharedContext()
  const {state} = useLocalState()

  const generateCsv = (): string => {
    shared.gridPoints.setConfig(state.gridPointsConfig);
    const points = shared.gridPoints;
    const rows = Math.ceil(points.getNumRows());
    const cols = Math.ceil(points.getNumCols());
    const gridSize = state.gridPointsConfig.gridSize

    // generate matrix
    const matrix = new Float32Array(rows * cols);
    matrix.fill(-1);
    points.forEach((row, col, value) => {
      let index = row * cols + col
      console.log(index)
      matrix[index] = value;
    })

    // generate csv header
    let csv = '[mm]';
    for (let i = 0; i < cols; i++) {
      csv += ",";
      csv += i * gridSize;
    }

    // generate rows
    for (let row = 0; row < rows; row++) {
      csv += '\n'
      csv += row * gridSize
      for (let col = 0; col < cols; col++) {
        let value = matrix[row * cols + col]
        csv += ','
        if (value >= 0) {
          csv += value.toFixed(2)
        }
      }
    }

    return csv;

  }

  // Source https://stackoverflow.com/a/29304414
  var download = function (content: string, fileName: string, mimeType: string) {
    var a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      // only this mime type is supported
      window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
    }
  }

  const onClick = () => {
    download(generateCsv(), 'export.csv', 'text/csv;encoding:utf-8');
  }

  return (
    <Button
      icon="pi-external-link"
      variant="secondary"
      onClick={onClick}
      title="Not available yet"
    >
      Export
    </Button>)

}
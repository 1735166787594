import React, { useState, useEffect } from "react"
import { useOktaAuth } from "@okta/okta-react"
import useAuthInterceptor from "../utils/useAuthInterceptor"

interface UserInfo {
  name: string
  groups: [string]
}

const UserInfo: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const { authState, authService } = useOktaAuth()
  useAuthInterceptor()
  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null)
    } else {
      authService.getUser().then((info: any) => {
        setUserInfo(info)
      })
    }
  }, [authService, authState]) // Update if authState changes

  return (
    <div className="flex flex-col justify-end text-right">
      {userInfo && <div>{userInfo?.name && <div>{userInfo.name}</div>}</div>}
      {userInfo && (
        <div className="text-xs text-gray-500">
          {userInfo?.groups?.length > 0 && (
            <div>{userInfo?.groups?.join(", ")}</div>
          )}
        </div>
      )}
    </div>
  )
}
export default UserInfo

import React from "react"
import { GateLocation } from "../api/scans/SharedTypes"
import Input from "./Input"
import Heading from "./Heading"

export default function GateSettings({
  gate,
  values,
  onChange,
}: {
  gate: string
  values?: GateLocation
  onChange: (val: any) => void
}) {
  const setValue = (e: any): void => {
    onChange({
      [e.target.name]: Number(e.target.value),
    })
  }
  if (!values) {
    return null
  }
  return (
    <div>
      <Heading>Gate {gate}</Heading>
      <div className="space-y-2">
        <Input
          type="number"
          label="Start"
          name="start"
          value={values.start}
          onChange={setValue}
        />
        <Input
          type="number"
          label="Range"
          name="range"
          value={values.range}
          onChange={setValue}
        />
        <Input
          type="number"
          label="Level"
          name="level"
          value={values.level}
          onChange={setValue}
        />
      </div>
    </div>
  )
}

import React from "react"
import Input from "./Input"
import { SelectionMode } from "../api/scans/GridPoints"

interface Settings {
  gridSize: number
  selectionMode: SelectionMode
}

interface ViewSettingsProps {
  initialState: Settings
  onChange: (property: Settings) => void
}

const ViewSettings: React.FC<ViewSettingsProps> = ({
  initialState,
  onChange,
}) => {
  return (
    <div className="space-y-4">
      <div>
        {/* <div className="label">Map Type</div> */}
        {/* <Dropdown
          value={initialState.mapType}
          className="input"
          options={mapTypes}
          onChange={(e): void => {
            onChange({ ...initialState, mapType: e.target.value })
          }}
          placeholder="Select a Map Type"
        /> */}
      </div>
      <Input
        type="number"
        label="Grid Size"
        name="gridSize"
        min={1}
        max={99}
        value={initialState.gridSize}
        onChange={(e): void => {
          onChange({
            ...initialState,
            gridSize: Number(e.target.value),
          })
        }}
      />
    </div>
  )
}
export default ViewSettings

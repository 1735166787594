import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import Navbar from "../components/Navbar"
import Heading from "../components/Heading"

const Profile: React.FC = () => {
  const { keycloak } = useKeycloak()

  const [state, setState] = React.useState({
    name: "",
    email: "",
    id: "",
  })

  React.useEffect(() => {
    keycloak?.loadUserInfo().then((userInfo: any) => {
      setState({
        name: userInfo.name,
        email: userInfo.email,
        id: userInfo.sub,
      })
    })
  }, [keycloak])

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="space-y-8 pt-8">
          <div>
            <Heading size="lg">Profile</Heading>
            <Heading size="sm" muted>
              {state.name}
            </Heading>
          </div>
          <div>{state.id}</div>
        </div>
      </div>
    </div>
  )
}

export default Profile

export default class ACF {

  public static computeBiased(data: Uint8Array): Float32Array {
    const result = ACF.computeUnbiased(data);
    const n = result.length;
    for (let lag = 0; lag < n; lag++) {
      result[lag] *= n / (n - lag);
    }
    return result;
  }

  public static computeUnbiased(data: Uint8Array): Float32Array {
    const values = ACF.zeroMean(data);
    const acf = new Float32Array(values.length)
    acf[0] = 1.0;
    const range = ACF.computeLag(values, 0);
    for (let lag = 1; lag < acf.length; lag++) {
      acf[lag] = ACF.computeLag(values, lag) / range;
    }
    return acf;
  }

  private static zeroMean(data: Uint8Array): Int16Array {
    const result = new Int16Array(data.length)
    let sum = 0
    for (let i = 0; i < data.length; i++) {
      sum += data[i]
    }
    const mean = sum / data.length;
    for (let i = 0; i < data.length; i++) {
      result[i] = data[i] - mean;
    }
    return result
  }

  private static computeLag(values: Int16Array, lag: number): number {
    let sum = 0;
    for (let i = lag; i < values.length; i++) {
      sum += values[i] * values[i - lag];
    }
    return sum;
  }

}
import React from "react"
import { useQuery } from "react-query"

import { getPoints, getMap } from "../utils/scanService"
import GeneratedImage from "./GeneratedImage"
import ThreeDimensionalImage from "./ThreeDimensionalImage"
import { useSharedContext } from "../providers/SharedContextProvider"
import MinMaxSlider from "./MinMaxSlider"
import { useLocalState } from "../providers/LocalStateProvider"
import LoadingScreen from "../views/LoadingScreen"

const ViewImageArea: React.FC<{ scanId: string }> = ({ scanId }) => {
  const {
    state: shared,
    setGridPoints,
    setSurfaceMap,
    resetSharedContext,
  } = useSharedContext()
  const { state, setInitialGatesConfig, resetLocalState } = useLocalState()

  // @ts-ignore (react-query-types)
  const { isLoading, isError, data: gridPointsData, refetch } = useQuery(
    ["scanDetail", scanId, state.gatesConfig],
    getPoints
  )
  const { data: surfaceMapData } = useQuery(["getMap", scanId], getMap)

  React.useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.gatesConfig])

  React.useEffect(() => {
    if (gridPointsData) {
      // Set grid points when changed
      setGridPoints(gridPointsData)

      if (state.gatesConfig === null) {
        // When no custom gates config is set, set them to default values received from json
        setInitialGatesConfig(gridPointsData.getGates())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridPointsData])

  React.useEffect(() => {
    // Clean up gates when unmounting
    return function cleanup(): void {
      resetSharedContext()
      resetLocalState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (surfaceMapData) {
      setSurfaceMap(surfaceMapData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surfaceMapData])

  // const { annotations: receivedAnnotations } = {}

  // const [annotations, setAnnotations] = React.useState<
  //   AnnotationObject[] | undefined
  // >(receivedAnnotations)

  // const [mutate] = useMutation(editAnnotations, {
  //   onSuccess: () => {
  //     // @ts-ignore
  //     queryCache.invalidateQueries(["scanDetail", scanId])
  //   },
  // })

  // function updateAnnotations(): void {
  //   try {
  //     mutate({ id: scanId, annotations })
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  // React.useEffect(() => {
  //   console.log("UPDATING ANNOT")

  //   updateAnnotations()
  // }, [annotations, updateAnnotations])

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    return <div>Error</div>
  }

  if (!shared.gridPoints) {
    return null
  }

  // TODO: Does this rebuild the 3D surface every time we update the canvas?
  return (
    <div className="flex flex-wrap lg:flex-no-wrap">
      <div className="w-full lg:w-7/12 flex flex-no-wrap">
        <div style={{ width: "150px" }} className="flex-shrink-0">
          {shared.colorMap && shared.gridPoints && <MinMaxSlider />}
        </div>
        <div className="w-full p-8 flex items-center">
          <GeneratedImage />
        </div>
      </div>
      <div className="w-full lg:w-5/12 flex items-center">
        <div className="w-full h-full">
          {shared.surfaceMap && <ThreeDimensionalImage />}
        </div>
      </div>
    </div>
  )
}

export default ViewImageArea

import IndexRange from "./IndexRange"
import RangedColorMap from "./RangedColorMap"

export enum Orientation {
  Horizontal,
  Vertical,
}

export default class HistogramData extends IndexRange {
  public setRange(min: number, max: number) {
    super.setRange(min, max)
    this.clear()
  }

  public clear() {
    this.buckets.fill(0)
  }

  public add(value: number) {
    if (isFinite(value)) {
      const index = this.getIndex(value, this.buckets.length)
      this.buckets[index] += 1
    }
  }

  public getBuckets(): number[] {
    return this.buckets
  }

  private static drawBar(
    ctx: any,
    x: number,
    y: number,
    w: number,
    h: number,
    color: string
  ) {
    ctx.save()
    ctx.fillStyle = color
    ctx.fillRect(x, y, w, h)
    ctx.restore()
  }

  public drawOn(
    canvas: HTMLCanvasElement,
    orientation: Orientation,
    colorMap?: RangedColorMap,
    logScale?: boolean
  ) {
    const ctx = canvas.getContext("2d")
    const canvasHeight = canvas.clientHeight
    const canvasWidth = canvas.clientWidth

    ctx?.clearRect(0, 0, canvasWidth, canvasHeight)

    // Logarithmic Scale
    if (logScale) {
      for (let i = 0; i < this.buckets.length; i++) {
        // log de-weighs too much, so we use roots instead
        this.buckets[i] = Math.pow(this.buckets[i], 1/3.0);
      }
    }

    const maxValue = Math.max(...this.buckets)
    this.buckets.forEach((value, idx) => {
      const normValue = value / maxValue
      const color = colorMap?.getColor(
        (idx / this.buckets.length) * this.range + this.offset
      ) || ''

      if (orientation === Orientation.Horizontal) {
        const barWidth = Math.round(canvasWidth / this.buckets.length)
        const x = idx * barWidth + 1
        const width = barWidth
        const height = normValue * canvasHeight
        const y = canvasHeight - height
        HistogramData.drawBar(ctx, x, y, width, height, color)
      } else {
        const barHeight = Math.round(canvasHeight / this.buckets.length)
        const x = 0
        const y = idx * barHeight + 1
        const width = normValue * canvasWidth
        const height = barHeight
        HistogramData.drawBar(ctx, x, canvasHeight - y, width, height, color)
      }
    })
  }

  constructor(numBuckets: number) {
    super()
    this.buckets = new Array(numBuckets)
  }

  private buckets: number[]
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react"
import { Canvas } from "react-three-fiber"
import { BackSide, CanvasTexture, FrontSide, Group, Vector3 } from "three"
import { OrbitControls } from "drei"

import { useLocalState } from "../providers/LocalStateProvider"
import { useSharedContext } from "../providers/SharedContextProvider"
import Button from "./Button"

const axisScale = new Vector3(0.25, 0.25, 0.25)

const ThreeDimensionalImage = () => {
  const { state } = useLocalState()

  const { state: shared } = useSharedContext()

  const group = React.useRef<Group>()
  const controls = React.useRef<OrbitControls>()

  const { surfaceMap } = shared
  if (!state.canvas || !surfaceMap) {
    return null
  }

  const rotateCW = () => {
    if (group.current) {
      group.current.rotation.z -= Math.PI / 8
    }
  }

  const rotateCCW = () => {
    if (group.current) {
      group.current.rotation.z += Math.PI / 8
    }
  }

  const resetView = () => {
    if (group.current) {
      group.current.rotation.z = 0
    }
    if (controls.current) {
      // @ts-ignore
      controls.current.reset()
    }
  }

  const geometry = surfaceMap.getGeometry()
  const texture = new CanvasTexture(state.canvas)

  // Scale based on the size of the object such that each geometry
  // is initially rendered using a comparable amount of screen area
  const objScale = 2.5 / surfaceMap.getRadius()
  const scale = new Vector3(objScale, objScale, objScale)

  // The geometry is centered, but we still want the coordinate frame to
  // be shown at the origin of the original world frame (i.e. robot base)
  const worldOrigin = new Vector3()
    .multiplyVectors(surfaceMap.getTranslation(), scale)
    .multiplyScalar(-1)

  // Note: we render two separate meshes for front and back, so that the
  // ordering works. This mitigates artifacts when rendering meshes with
  // transparent holes like elbows
  // (see https://github.com/mrdoob/three.js/issues/2476#issuecomment-9073528)
  return (
    <div className="relative w-full h-full bg-gray-100 lg:border-l">
      <Canvas style={{ height: "100%", minHeight: "400px" }}>
        <OrbitControls ref={controls} dampingFactor={0.75} />
        <scene>
          <group ref={group}>
            <ambientLight color={0xffffff} />
            <axesHelper scale={axisScale} position={worldOrigin} />
            <mesh geometry={geometry} scale={scale}>
              <meshStandardMaterial
                attach="material"
                map={texture}
                side={BackSide}
              />
            </mesh>
            <mesh geometry={geometry} scale={scale}>
              <meshStandardMaterial
                attach="material"
                map={texture}
                side={FrontSide}
              />
            </mesh>
          </group>
        </scene>
      </Canvas>
      <div className="space-x-2 flex justify-end mr-8 mt-8 absolute top-0 right-0">
        <Button
          icon="pi-angle-left"
          variant="secondary"
          size="sm"
          onClick={rotateCW}
        />
        <Button
          icon="pi-angle-right"
          variant="secondary"
          size="sm"
          onClick={rotateCCW}
        />
        <Button
          icon="pi-angle-up"
          variant="secondary"
          size="sm"
          onClick={resetView}
        />
      </div>
    </div>
  )
}

export default ThreeDimensionalImage

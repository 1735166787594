import React from "react"
import Heading from "./Heading"
import Button from "./Button"
import ViewSettings from "./ViewSettings"
import { useLocalState } from "../providers/LocalStateProvider"

interface ExportModalProps {
  close: () => void
  selectedScans: string[]
}

const ExportModal: React.FC<ExportModalProps> = ({ selectedScans, close }) => {
  const { state } = useLocalState()

  const createExport = (): void => {
    alert("Send Post to Export endpoint")
    close()
  }

  // const { mapType } = view.state
  const { gridSize, selectionMode } = state.gridPointsConfig

  const [exportView, setExportView] = React.useState({
    selectionMode,
    gridSize: Number(gridSize),
  })

  return (
    <div className="space-y-4">
      <Heading>Export</Heading>
      {selectedScans.length > 1 && (
        <p>You are about to export {selectedScans.length} scans</p>
      )}
      <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8">
        <div className="w-1/2">
          <ViewSettings initialState={exportView} onChange={setExportView} />
        </div>
        <div className="w-1/2">
          <code className="p-8 bg-gray-300 rounded flex flex-col w-full">
            {Object.entries(exportView).map(([key, value]) => (
              <React.Fragment key={key}>
                {key}: {value}
                <br />
              </React.Fragment>
            ))}
          </code>
        </div>
      </div>

      <div className="space-x-2">
        <Button size="lg" variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button size="lg" onClick={createExport}>
          Export
        </Button>
      </div>
    </div>
  )
}

export default ExportModal

import React from "react"
import { useMutation, queryCache } from "react-query"
import { useParams } from "react-router-dom"
import Heading from "./Heading"
import Button from "./Button"
import Input from "./Input"
import { addProfile } from "../utils/scanService"
import { useLocalState } from "../providers/LocalStateProvider"

interface SaveProfileModalProps {
  close: () => void
}

const SaveProfileModal: React.FC<SaveProfileModalProps> = ({ close }) => {
  const [name, setName] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const { state } = useLocalState()
  const { id } = useParams()

  const [mutate] = useMutation(addProfile, {
    onSuccess: () => {
      // @ts-ignore
      queryCache.invalidateQueries(["scanDetail", id])
    },
  })

  const onApply = async (): Promise<void> => {
    setLoading(true)
    const response = await mutate({
      id,
      name,
      profile: {
        gridPointsConfig: state.gridPointsConfig,
        gatesConfig: state.gatesConfig,
        colorMapConfig: state.colorMapConfig,
      },
    })
    if (response) {
      close()
    } else {
      alert("Error, try again")
    }
    setLoading(false)
  }

  return (
    <div className="space-y-4">
      <Heading>Save Profile</Heading>
      <p>You can save your current settings for future use.</p>
      <div>
        <Input
          type="text"
          label="Name"
          name="name"
          value={name}
          onChange={(e): void => setName(e.target.value)}
        />
      </div>
      <div className="space-x-2 flex">
        <Button size="lg" variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          size="lg"
          type="submit"
          onClick={onApply}
          loading={loading}
          disabled={name.length < 1}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default SaveProfileModal

import React from "react"

const ErrorScreen: React.FC = ({ children }) => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="bg-danger-300 p-8 rounded-lg flex items-center">
        <div className="pi pi-times text-4xl text-danger-500 mr-2" />
        <div className="text-danger-700 font-bold">{children}</div>
      </div>
    </div>
  )
}

export default ErrorScreen

import React from "react"

const LoadingScreen: React.FC = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default LoadingScreen
